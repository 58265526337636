enum DescriptorName {
  stand1 = 'stand',
  plots = 'plots',
  operations = 'operations',

  stand = 'Stand',
  specialFeature = 'SpecialFeature',
  restrictions = 'Restriction',
  treeStand = 'TreeStand',
  stratum = 'Stratum',
  deadStratum = 'DeadTreeStratum',
  treatments = 'Treatment',
  operation = 'Operation',
  assortment = 'Assortment',
  specification = 'Specification',
  planningArea = 'PlanningArea',
}

export default DescriptorName;
