import { action, computed, makeObservable, observable } from 'mobx';
import { Stand, SyncStatus } from './Stands';
import { LayersLoader } from './map/LayersLoader';
import { appModel } from './App';
import { MapboxDB } from './MapboxDB';
import { DescriptorComplex } from './DataNodeUtils';
import DescriptorName from './DescriptorName';
import { ChangedData, DDataNode } from '@simosol/iptim-data-model';
import arraySearch from '../arraySearch';
import { DProject } from './Projects';
import DB from './DB';
import { UtilsMap } from '../utils/UtilsMap';
import { Feature, MultiPolygon } from 'geojson';

export class Project {
  @observable.ref
  private _data!: DProject;

  @observable.shallow
  stands: Stand[] = [];

  private readonly _id: string;
  private readonly _name: string;
  private readonly _code: string;
  private readonly _clients: string[];
  readonly geoJSON: Feature<MultiPolygon> | undefined;

  readonly layersLoader = new LayersLoader(() => this.selectedStands);

  constructor(data: DProject) {
    makeObservable(this);
    this._id = data.uid;
    this._name = data.name;
    this._code = data.code;
    this._clients = data.clients;

    if (data.geom) {
      this.geoJSON = UtilsMap.geomToGeoJSON(data.geom) as Feature<MultiPolygon>;
    }
    // noinspection JSIgnoredPromiseFromCall
    this.setData(data);
  }

  @observable
  private _selectedStands: Stand[] = [];
  @computed
  get selectedStands() { return this._selectedStands; }

  @computed
  get selectedIds() { return this._selectedStands.map(v => v.id); }
  // set selectedStands(value) { this._selectedStands = value; }

  get layersDb() {
    const allIds: number[] = [];
    this.stands.forEach(stand => allIds.push(...stand.layersDb));

    return Array.from(new Set(allIds));
    // which layers were loaded at all stands
    /*const allStandsLength = this.stands.length;
    return Array.from(new Set(allIds))
      .filter(layerId => allIds.filter(id => id === layerId).length === allStandsLength);*/
  }

  @action
  setStandToRemove = (stands: Stand[]) => {
    if (!stands.length) return;
    stands.forEach((stand) => {
      stand.setStandStatus(SyncStatus.del);
    });
    this._selectedStands = [];
  }

  @action
  removeSelectedStands = async (stands: Stand[]) => {
    if (!stands.length) return;
    stands.map(async (stand) => {
      const standIndex = this.stands.findIndex(st => st.id === stand.id);
      if (standIndex > -1) this.stands.splice(standIndex, 1);
      await appModel.db.remove(DB.getName(this.id, stand.id));
      this.deleteStandData(`${stand.id}`);

      // download again links from server, because we dont know witch links to delete
      if (stand.layersDb.length) {
        Promise.all(stand.layersDb.map((layerId) => {
          const promise = appModel.api.getTilesCache([stand.id], undefined, layerId);

          promise.then((result) => {
            const links = result.data ? Object.keys(result.data) : [];
            if (links.length) MapboxDB.delMultiple(links);
          });

          return promise;
        }));
      }
    });
  }

  @action
  onSelectAll = () => {
    if (this.isAllEstatesSelected) {
      this._selectedStands = [];
    } else {
      this._selectedStands = this.stands.filter(
        v => v.syncStatus !== SyncStatus.del && v.syncStatus !== SyncStatus.add,
      );
    }
  }
  @computed
  get isAllEstatesSelected() {
    return this._selectedStands.length === this.stands.filter(
      v => v.syncStatus !== SyncStatus.del && v.syncStatus !== SyncStatus.add,
    ).length;
  }
  @action
  toggleStand = (stand: Stand) => {
    const index = this.selectedIds.indexOf(stand.id);
    if (index > -1) {
      this._selectedStands.splice(index, 1);
    } else {
      this._selectedStands.push(stand);
    }
  }

  @computed
  get standsCreationProgress() {
    const standsTotal = this.standsTotal;
    if (standsTotal === undefined) return 0;
    const standsCreated = this.standsCreated;
    return standsCreated < standsTotal ? standsCreated / standsTotal : 1;
  }

  @computed
  get standsTotal(): number | undefined {
    if (!this._data) return undefined;
    return this._data.data.length;
  }
  @computed
  get standsCreated() {
    return this.stands.length;
  }

  @computed
  get allStandsCreated() {
    return this.standsCreationProgress === 1;
  }

  get id() { return this._id; }
  get name() { return this._name; }
  get code() { return this._code; }
  get clients() { return this._clients; }

  setData = async (data: DProject) => {
    this.stands = [];
    this._data = data;
  }
  addStandData = (stand: DDataNode) => {
    this._data.data.push(stand);
  }
  deleteStandData = (standId: string) => {
    this._data.data = this._data.data.filter(sd => sd.id !== standId);
  }

  createStands = async () => {
    const data = this._data;
    const standsDB = await appModel.db.getStandsLayers();
    const structure = this.fixStructure();

    const len = data.data.length;
    for (let i = 0; i < len; i += 1) {
      const standData = data.data[i];
      const standRowId = DB.getName((standData as any).id, this.id);
      const layers = standsDB.find(v => v.id === standRowId)?.layers ?? [];
      await this._addStandData(standData, structure, layers);
    }
  }

  fixStructure = () => {
    const structure = this._data.structure as unknown as DescriptorComplex;

    // TODO: Адаптация данных с КИТа
    if (Array.isArray(structure.properties)) {
      const tr = (structure.properties as [])
        .find(p => p['name'] === DescriptorName.treatments) as unknown as DescriptorComplex;
      if (tr) {
        const properties = structure.properties;
        const op = (tr.properties as [])
          .find(o => o['name'] === DescriptorName.operation) as unknown as DescriptorComplex;
        const { name, ...props } = op;
        // @ts-ignore
        properties.push({ name: DescriptorName.operations as const, ...props });
        structure.properties = properties;
      }
    }
    return structure;
  }

  private _addStandData = (
    standData: DDataNode,
    structure: DescriptorComplex,
    layersDB: number[],
  ): Promise<DDataNode> => {
    return new Promise((resolve) => {
      requestAnimationFrame(() => {
        const newStand = new Stand(this, standData, structure, layersDB);
        newStand.syncStatus = standData['syncStatus'] as SyncStatus;
        this.stands.push(newStand);
        resolve(standData);
      });
    });
  }

  @action
  getStand = (standId: string) => {
    return arraySearch('id', standId, this.stands);
  }

  @computed
  get data() { return this._data; }

  @computed
  get standsCount() {
    return this.stands.length;
  }

  get standsMunicipality() {
    return [...new Set(this.stands.map(stand => stand.municipality))];
  }

  @computed
  get area() {
    let area = 0;
    this.stands.forEach((stand) => {
      area += stand.area;
    });
    return Math.round(area);
  }

  @computed
  get version() {
    let sum = 0;
    this.stands.forEach((stand: Stand) => {
      sum += stand.version;
      sum += +(stand.syncStatus === SyncStatus.del);
    });
    return sum;
  }

  @computed
  get changedData(): ChangedData[]  {
    let res: ChangedData[] = [];
    for (const stand of this.stands) {
      res = res.concat(stand.changedData);
    }
    return res;
  }

  commit = () => {
    this.stands.forEach(stand => stand.commit());
  }
}

export default Project;
