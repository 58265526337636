import { PageTask } from '../../Browser';
import { LangKey } from '../../LangKey';
import { computed, makeObservable } from 'mobx';
import { appModel } from '../../models/App';
import { AppToolbarTabs } from '../app/appBar/AppToolbar';

export default class TaskTabs implements AppToolbarTabs {
  public data:{ label: string, value: string | number } [] = [];
  constructor() {
    this.data = [
      { label: LangKey.TaskInfo, value: PageTask.info },
    ];
    if (!appModel.isTapio) this.data.push({ label: LangKey.TaskNotes, value: PageTask.notes });
    makeObservable(this);
  }

  @computed get value(): string | number {
    const pg = appModel.browser.page;
    return pg.p === 'task' ? pg.p2 : '';
  }

  onClick = (value: string | number) => {
    const pg = appModel.browser.page;
    if (pg.p === 'task') {
      appModel.browser.page = value === PageTask.notes
        ? { p: pg.p, p1: pg.p1, p2: PageTask.notes, p3: 'view' }
        : { p: pg.p, p1: pg.p1, p2: value as (PageTask.info) };
    }
  }
}
