import { appModel, DApp, LOCAL_OFFLINE_KEY } from './App';
import { ApiMethodResponse, ErrorType } from '../API';
import DB from './DB';
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import DataLoader from './DataLoader';
import Project from './Project';
import { DDataNode } from '@simosol/iptim-data-model';
import { Stand } from './Stands';
import Backup, { BackupData } from '../Backup';

export default class DataSync {
  private _db: DB;
  private _backup: Backup;
  private _isSyncing = false;
  private _syncAgain = false;
  private _dataVersion = 0;

  private _isInitializing = false;

  private _syncReaction?: IReactionDisposer;
  private _syncIntervalId?: number;

  @observable
  criticalError?: string;

  @observable.ref
  initProgress: (() => number) | number = 0;

  constructor(db: DB, backup: Backup) {
    makeObservable(this);
    this._db = db;
    this._backup = backup;
  }

  init = () => {
    reaction(
      () => this.authorized,
      (authorized) => {
        if (authorized) {
          this._sync();
        } else {
          // noinspection JSIgnoredPromiseFromCall
          this.onLogout();
        }
      },
      { fireImmediately: true },
    );
  }

  @computed
  get initialized() {
    const progress = typeof this.initProgress === 'number' ? this.initProgress : this.initProgress();
    return progress === 1;
  }

  @action
  onLogout = async () => {
    appModel.userRole = undefined;
    appModel.projects.clear();
    if (this._syncReaction) this._syncReaction();
    this._isSyncing = false;
    this._isInitializing = false;
    this._syncAgain = false;
    this.initProgress = 0;
    if (this._syncIntervalId) window.clearInterval(this._syncIntervalId);
    await this._db.destroyAndCreateNew();
  }

  /**
   * First time sync
   * @returns {Promise<void>}
   * @private
   */
  private _syncFirst = async () => {
    if (this.initialized || this._isInitializing) return;
    this._isInitializing = true;
    this.initProgress = 0;

    const standsCreationProgress = (startValue: number, endValue: number) => () => {
      const standsTotal = appModel.projects.standsTotal;
      if (standsTotal === undefined) return startValue;
      return appModel.projects.standsCreated / standsTotal * Math.max(endValue - startValue) + startValue;
    };

    appModel.mapElements.sync();

    // check if database is available
    let appData = undefined;
    try {
      const dbRes = await this._db.get();
      this.initProgress = 0.1;
      if (!this.authorized) return;
      appData = dbRes;
      // initialize application data first time from db
      this.initProgress = standsCreationProgress(0.1, 0.3);
      await this._updateFromData(appData);
    } catch (e: any) {
      if (e.status !== 404) {
        console.log('sync first error');
        console.log(e);
        // something went wrong, other error handling
        // TODO handle critical errors
      }
    }
    // when database available it could have some changes, sync it
    if (appData !== undefined) {
      await this._syncNext();
      if (!this.authorized) return;
    }

    // get new data from server
    const loader = new DataLoader();
    this.initProgress = () => {
      if (loader.partsTotal === undefined) return 0.3;
      return 0.3 + loader.partsLoaded / loader.partsTotal * 0.5;
    };

    if (!appModel.isOffline) {
      const getDataRes = await loader.load();
      if (!this.authorized) return;
      if (!getDataRes.error && getDataRes.data) {
        // with network and new data, update from this data
        appData = getDataRes.data;
        await this._db.updateOrInsert(appData);
        // initialize application data second time from external source
        // todo compare and update only if data was really changed
        this.initProgress = standsCreationProgress(0.8, 0.95);
        await this._updateFromData(appData);
      } else {
        // if there is no network
        if (getDataRes.error && getDataRes.error.type === ErrorType.network) {
          // in offline mode without internal db we can't show anything
          if (!appData) {
            // todo handle network error
          }
        } else {
          // todo handle critical errors
        }
      }
    }

    this._isInitializing = false;
    this.initProgress = 1;

    if (this._syncReaction) this._syncReaction();
    this._syncReaction = reaction(
      () => this.projects.version,
      (version: number) => {
        if (version === 0) return;
        this._sync();
      },
      { fireImmediately: false });

    if (this._syncIntervalId) window.clearInterval(this._syncIntervalId);
    this._syncIntervalId = window.setInterval(() => this._sync(), 10 * 1000);
  }

  /*
  Second time sync
   */
  private _syncNext = async () => {
    const data = this.getAppData();

    if (!data) return;

    const projectsVersion = this.projects.version;
    const fgVersion = this.freeGeometry.version;

    const unsavedData = this.unsavedData;
    // must be divided, because different endpoints
    const unsavedFG = appModel.freeGeometries.getUnsavedFGs();
    const unsavedStands = await appModel.db.getUnsavedStands();

    // check if app has unsaved data
    if (unsavedData === undefined
      && unsavedFG.length === 0
      && unsavedStands?.deletedStands.length === 0
      && unsavedStands?.addedStands.length === 0
    ) {
      return;
    }

    // if we are already syncing, we should sync again after current sync is complete
    if (this._isSyncing) {
      this._syncAgain = true;
      return;
    }
    // set the syncing flag
    this._isSyncing = true;

    const badRequest: BackupData = [];

    const checkResponse = (res: ApiMethodResponse) => {
      if (!this.authorized) return;
      const networkError = res.error && res.error.type === ErrorType.network;
      if (res.error && !networkError) {
        // if some other error, except network error happened
        this.criticalError = res.error.message;
        this._isSyncing = false;
        this._syncAgain = false;
        return;
      }
      return networkError;
    };

    if (!appModel.isOffline && appModel.needBackup) {
      const payload = this._backup.makeBody(unsavedFG, unsavedData, unsavedStands);
      if (payload.length) {
        await this._backup.saveFile(payload);
        const res = await this._backup.sendAllRequest(payload);
        const networkError = checkResponse(res);
        if (networkError && appModel.isTapioIsEstates) {
          appModel.isOffline = true;
        } else {
          appModel.needBackup = false;
          localStorage.removeItem(LOCAL_OFFLINE_KEY);
        }
      }
    }

    // add all new stands
    if (unsavedStands && !appModel.isOffline && unsavedStands.addedStands.length > 0) {
      for (const stand of unsavedStands.addedStands) {
        let networkError;
        const contents = await appModel.api.standAdd(stand);
        networkError = checkResponse(contents);
        if (contents.error && !networkError) {
          badRequest.push({
            url: process.env.REACT_APP_API_URL + '/stands/',
            body: stand,
            method: 'POST',
          });
          const estateId = stand['estate'] as string;
          if (!estateId) continue;
          const project: Project | undefined = appModel.projects.get(estateId);
          if (!project) continue;
          appModel.db.remove(DB.getName(project.id, stand.id));
          project.stands = project.stands.filter(s => s.id !== stand.id);
          project.deleteStandData(`${stand.id}`);
        }
        if (!networkError) {
          this._dataVersion -= unsavedStands.addedStands.length;
          if (contents.data) {
            // @ts-ignore
            const dStand = contents.data.data;
            appModel.instanceControl.setId(stand.id as string, dStand['id'] as string);
          }
        } else if (networkError) {
          appModel.isOffline = true;
        }
      }
    }

    // change all data in all instance
    if (unsavedData && !appModel.isOffline) {
      // send unsaved data to server
      const contents = await appModel.api.setDataChanged(unsavedData);
      const networkError = contents.some((c) => checkResponse(c));
      const contentsError = contents.some((c) => c.error);
      if (contentsError && !networkError) {
        badRequest.push({
          url: process.env.REACT_APP_API_URL + '/dataSetChanged',
          body: { ...unsavedData },
          method: 'POST',
        });
        this._commit();
      }
      // without version checking it may happen when data was changed while sending the request.
      // we can't commit this changed data. we can only commit it after successful sending to the server
      if (!networkError && projectsVersion === this.projects.version) {
        this._dataVersion -= projectsVersion;
        this._commit();
      } else if (networkError && appModel.isTapioIsEstates) {
        appModel.isOffline = true;
      }
    }

    // refresh all created stands
    if (unsavedStands && unsavedStands.addedStands.length > 0 && !appModel.isOffline) {
      for (const stand of unsavedStands.addedStands) {
        const estateId = stand['estate'] as string;
        if (!estateId) continue;
        const project: Project | undefined = appModel.projects.get(estateId);
        if (!project) continue;
        const id = appModel.instanceControl.getId(`${stand.id}`);
        if (!id) continue;
        const res = await appModel.api.standGet(id);
        // @ts-ignore
        const dStand: DDataNode = res.data.data;

        await appModel.db.setNewFullStand(DB.getName(project.id, dStand['id'] as string), dStand);
        project.stands.push(new Stand(project, dStand, project.fixStructure(), []));
        project.addStandData(dStand);

        appModel.db.remove(DB.getName(project.id, stand.id));
        project.stands = project.stands.filter(s => s.id !== stand.id);
        project.deleteStandData(`${stand.id}`);
      }
    }

    // delete stands
    if (unsavedStands && !appModel.isOffline && unsavedStands.deletedStands.length > 0) {
      let networkError;
      const contents = await appModel.api.deleteStandBulk(unsavedStands.deletedStands.map(s => s.id));
      networkError = checkResponse(contents);
      if (contents.error && !networkError) {
        badRequest.push({
          url: process.env.REACT_APP_API_URL + '/stands/bulk/',
          body: unsavedStands.deletedStands.map(s => s.id),
          method: 'DELETE',
        });
        for (const stand of unsavedStands.deletedStands) {
          const estateId = stand['estate'] as string;
          if (!estateId) continue;
          const project: Project | undefined = appModel.projects.get(estateId);
          if (!project) continue;
          appModel.db.remove(DB.getName(project.id, stand.id));
          project.stands = project.stands.filter(s => s.id !== stand.id);
          project.deleteStandData(`${stand.id}`);
        }
      }
      if (!networkError) {
        this._dataVersion -= unsavedStands.deletedStands.length;
        unsavedStands.deletedStands.forEach((s) => {
          s.project.removeSelectedStands([s]);
        });

      } else if (networkError) {
        appModel.isOffline = true;
      }
    }

    if (unsavedFG.length) {
      if (!appModel.isOffline) {
        let networkError;
        const contents = await Promise.all(unsavedFG.map(async fg => appModel.api.setGetFreeGeometry(fg)));
        networkError = checkResponse(contents[0]);
        if (!networkError && fgVersion === this.freeGeometry.version) {
          this._dataVersion -= fgVersion;
          this._fgCommit();
        } else if (networkError && appModel.isTapioIsEstates) {
          appModel.isOffline = true;
        }
      }
    }

    if (badRequest.length) {
      await this._backup.sendFailedRequest(badRequest);
      badRequest.length = 0;
    }

    try {
      // returns the version of the entire application,
      // taking the versions of projects,
      // special tasks and created stands
      const totalVer = projectsVersion + fgVersion
        + (unsavedStands?.deletedStands.length ?? 0)
        + (unsavedStands?.addedStands.length ?? 0);
      if (totalVer !== this._dataVersion) {
        this._db.updateOrInsert(data);
        this._dataVersion = totalVer;
      }
    } catch (e) {
      console.log('sync next error', e);
      throw (e);
    }

    if (!this.authorized) return;

    this._isSyncing = false;
    if (this._syncAgain) {
      this._syncAgain = false;

      await this._syncNext();
      if (!this.authorized) return;
    }
  }

  @computed
  get unsavedData() {
    const tasks = appModel.tasks;
    const uNotes = tasks.getUnsavedNotes();
    const uProjects = appModel.projects.changedData;
    if (uNotes.length === 0 && Object.keys(uProjects).length === 0) return undefined;
    return { notes: uNotes, projects: uProjects };
  }

  private _commit = () => {
    this.projects.commit();
  }
  private _fgCommit = () => {
    this.freeGeometry.commit();
  }

  @computed
  get projects() {
    return appModel.projects;
  }
  @computed
  get freeGeometry() {
    return appModel.freeGeometries;
  }

  getAppData = (): DApp | undefined => {
    return appModel.getAppData();
  }
  @action
  private _updateFromData = async (data: DApp) => {
    return appModel.updateFromData(data);
  }

  @computed
  get authorized() {
    return appModel.authorized;
  }

  private _sync = () => {
    if (this.initialized) {
      // noinspection JSIgnoredPromiseFromCall
      this._syncNext();
    } else {
      // noinspection JSIgnoredPromiseFromCall
      this._syncFirst();
    }
  }
}
