import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { appModel } from '../../models/App';
import { FreeGeometry } from '../../models/FreeGeometries';
import AppToolbar from '../app/appBar/AppToolbar';
import { LangKey } from '../../LangKey';
import { Checkbox, FormControlLabel } from '@mui/material';
import DataPreloader from '../../uicomponents/DataPreloader';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import UiText from '../../uicomponents/UiText';
import { taskColorFromStatus } from '../tasks/Tasks';
import { observer } from 'mobx-react-lite';
import { joinSecondary } from '../../models/Utils';

const useStyles = makeStyles((theme: Theme) => createStyles({
  spacer: {
    flexGrow: 1,
  },
}));

const FreeGeometries = observer(() => {
  const styles = useStyles();
  const fgs = appModel.freeGeometries.freeGeometries;
  const showOnlyAssignedToMe = appModel.onlyAssigned;

  React.useMemo(
    () => {
      const userId = Number(localStorage.getItem('userId'));
      fgs.forEach(el => el.assignedToMe = Number(el.operationAssignee) === userId);
      return fgs;
    },
    [fgs],
  );

  const filteredFG = showOnlyAssignedToMe && appModel.isTapio
    ? fgs.filter(fg => fg.assignedToMe)
    : fgs;

  const onFGClick = (fg: FreeGeometry) => () => {
    appModel.browser.page = { p: 'freeGeometry', p1: `${fg.id}` };
  };

  return (
    <>
      <AppToolbar title={LangKey.FreeGeometryTitle} button={{ type: 'menu' }}>
        <>
          <div className={styles.spacer}/>
          {appModel.isTapio && (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={showOnlyAssignedToMe}
                  onChange={() => appModel.onlyAssigned = !appModel.onlyAssigned}
                  color="primary"
                />
              )}
              label={LangKey.TaskAssigned.t()}
            />
          )
          }
        </>
      </AppToolbar>
      <DataPreloader>
        {filteredFG.map(fg => (
          <ListItem button key={fg.id} onClick={onFGClick(fg)}>
            <ListItemIcon>
              <svg height="24" width="24">
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  stroke="none"
                  fill={taskColorFromStatus(fg.operationStatus)}
                />
              </svg>
            </ListItemIcon>
            <ListItemText
              primary={fg.name}
              secondary={joinSecondary([fg.operationAssigneeName, fg.operationDateStart, fg.executionDetails], ' / ')}
            />
            <UiText>{fg.plannedYear}</UiText>
          </ListItem>
        ))}
      </DataPreloader>
    </>
  );
});

export default FreeGeometries;
