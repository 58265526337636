import * as React from 'react';
import { Stand, SyncStatus } from '../../models/Stands';
import { Stand as MapStand } from '@simosol/stands-map';
import Project from '../../models/Project';
import { appModel } from '../../models/App';

const useMapStands = (
  currentProject?: Project,
): MapStand[] => {
  if (!currentProject) return [];
  const getStands = (): MapStand[] => {
    const stands: Stand[] = appModel.isTapio
      ? !appModel.onlyAssigned
        ? appModel.projects.reduce<Stand[]>(
          (total: Stand[], project: Project) => {
            total.push(...project.stands.filter(s => s.syncStatus !== SyncStatus.del));
            return total;
          },
          [],
        )
        : appModel.projects.reduce<Stand[]>(
          (total: Stand[], project: Project) => {
            total.push(...project.stands
              .filter(s => s.syncStatus !== SyncStatus.del)
              .filter(stand => stand.assignedToMe)
            );
            return total;
          },
          [],
        )
      : currentProject?.stands;
    const newStand: MapStand[] = [];
    stands.forEach((stand: Stand) => {
      if (!stand.geoJSONToMap) return;
      newStand.push({
        id: stand.id,
        geoJSON: stand.geoJSONToMap,
        priority: () => stand.priority,
        extensionLabel: stand.name,
        disabled: !appModel.isTapioIsEstates
          && (
            appModel.isTapio
            && (
              !stand.assignedToMe
              || stand.project.id !== currentProject.id
            )
          ),
        showLabelInDisabledStand: appModel.isTapio && (!stand.assignedToMe),
      });
    });
    return newStand;
  };
  return React.useMemo(
    () => getStands(),
    [currentProject, appModel.onlyAssigned]);
};

export default useMapStands;
