import { makeObservable, observable, runInAction } from 'mobx';

interface IdEntity {
  id: string;
}

export default class EntityCache<T extends IdEntity, D> {
  @observable.shallow
  private _entities: T[] = [];

  constructor(
    private _entityFactory: (data: D) => T,
    private _idGetter: (data:D) => string | undefined,
    private _updateEntityData: (entity: T, data: D) => void,
  ) {
    makeObservable(this);
  }

  fromData = (data: D) => this.fromArray([data])[0];
  fromArray = (data: D[]) => {
    const newEntities:T[] = [];
    runInAction(() => {
      for (const entityData of data) {
        const id = this._idGetter(entityData);
        let entity = id ? this.get(id) : undefined;
        if (!entity) {
          entity = this._entityFactory(entityData);
          this._entities.push(entity);
        } else {
          this._updateEntityData(entity, entityData);
        }
        newEntities.push(entity);
      }
    });
    return newEntities;
  }

  /*
    Array functions proxies
   */
  map = <S>(callback: (item: T) => S) => this._entities.map(callback);
  filter = (callback: (item: T) => boolean) => this._entities.filter(callback);
  forEach = (callBack: (item: T) => void) => { this._entities.forEach(callBack); };
  reduce = <S>(
    reducer: (accumulator: S, currentValue: T, currentIndex?: number, array?: T[]) => S,
    initialValue: S) => {
    return this._entities.reduce<S>(reducer, initialValue);
  }
  get length() { return this._entities.length; }

  get = (id: string) => {
    for (const e of this._entities) {
      if (e.id === id) return e;
    }
    return undefined;
  }

  clear = () => {
    this._entities = [];
  }
}
