const DB_NAME = 'mapboxIDB';
const STORE_NAME = 'tiles';

export class MapboxDB {
  private static connectDB = (transaction: (db: IDBDatabase) => void) => {
    const request = indexedDB.open(DB_NAME, 1);
    // request.onerror = logerr;
    request.onsuccess = () => transaction(request.result);
    request.onupgradeneeded = (event) => {
      (event.currentTarget as any)?.result
        .createObjectStore(STORE_NAME, { keyPath: 'url' });
      MapboxDB.connectDB(transaction);
    };
  }

  static indexedPut = (url: string, result: ArrayBuffer | string) => {
    if (!window.indexedDB) return;
    // console.log('indexedPut');

    const tile = {
      url,
      body: result,
    };

    MapboxDB.setTile(tile);
  }

  static delMultiple = (urls: string[]) => {
    urls.map(MapboxDB.getTile);
  }

  static delTile = (tile: string) => {
    MapboxDB.connectDB((db) => {
      const request = db.transaction([STORE_NAME], 'readwrite')
        .objectStore(STORE_NAME)
        .delete(tile);
      request.onsuccess = () => console.log('File delete from DB:', tile);
    });
  }

  static indexedGet = async (url: string) => {
    if (!window.indexedDB) return;
    return await MapboxDB.getTile(url);
  }

  private static getTile = (tile: string) => {
    return new Promise<IDBRequest<{ body?: ArrayBuffer | string }> | undefined>((resolve) => {
      MapboxDB.connectDB((db) => {
        const request = db.transaction([STORE_NAME], 'readonly')
          .objectStore(STORE_NAME)
          .get(tile);
        request.onerror = () => resolve(undefined);
        request.onsuccess = () => resolve(request.result ? request.result : null);
      });
    });

  }

  private static setTile = (tile: object) => {
    MapboxDB.connectDB((db) => {
      const request = db.transaction([STORE_NAME], 'readwrite')
        .objectStore(STORE_NAME)
        .put(tile);
      request.onerror = (err) => {
        console.log(err);
      };
      request.onsuccess = () => {
        return request.result;
      };
    });
    return false;
  }
}
