import * as React from 'react';
import { MapButton } from '@simosol/stands-map';

import { appModel } from '../../../models/App';
import { LangKey } from '../../../LangKey';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Group from '@mui/icons-material/Group';
import Person from '@mui/icons-material/Person';

type Props = {
  className: {
    root: string,
    button: string,
  },
};

const FGShowMapButton = (props: Props) => {
  const { className } = props;

  const tooltipTitle = appModel.onlyAssigned
    ? LangKey.FreeGeometryTooltipGroup.t()
    : LangKey.FreeGeometryTooltipPerson.t();

  const toggleAssignedToMe = () => {
    appModel.onlyAssigned = !appModel.onlyAssigned;
  };

  return (
    <MapButton className={className.root}>
      <Tooltip title={tooltipTitle} arrow placement={'right'}>
        <IconButton onClick={toggleAssignedToMe} className={className.button}>
          {appModel.onlyAssigned ? <Person /> : <Group />}
        </IconButton>
      </Tooltip>
    </MapButton>
  );
};

export default FGShowMapButton;
