import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Confirm from '../../uicomponents/Confirm';
import { appModel, LOCAL_OFFLINE_KEY } from '../../models/App';
import UiText from '../../uicomponents/UiText';
import { LangKey } from '../../LangKey';
import CloudOffTwoTone from '@mui/icons-material/CloudOffTwoTone';
import CloudQueue from '@mui/icons-material/CloudQueue';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { LOCAL_OFFLINE_BACKUP } from '../../Backup';

const useStyles = makeStyles((theme: Theme) => createStyles({
  text: {
    fontWeight: 700,
    cursor: 'pointer',
  },
}));

const OfflineToggle = observer(() => {
  const styles = useStyles();

  const toggleOffline = () => {
    appModel.isOffline = !appModel.isOffline;
    if (appModel.isOffline) {
      appModel.needBackup = true;
      localStorage.setItem(LOCAL_OFFLINE_BACKUP, 'true');
    }
    appModel.isOffline
      ? localStorage.setItem(LOCAL_OFFLINE_KEY, 'true')
      : localStorage.removeItem(LOCAL_OFFLINE_KEY);
  };

  return (
    <Confirm
      message={LangKey.OfflineToggleStatus.t(
        { status: appModel.isOffline ? LangKey.OfflineGetOnline.t() : LangKey.OfflineGetOffline.t() },
      )}
      onClick={toggleOffline}
      variant={'listItem'}
      icon={appModel.isOffline ? <CloudOffTwoTone /> : <CloudQueue />}
      actionsNo={{
        text: LangKey.CommonCancel.t(),
        color: 'inherit',
      }}
      actionsYes={{
        text: appModel.isOffline ? LangKey.OfflineGetOnline.t() : LangKey.OfflineGetOffline.t(),
        color: appModel.isOffline ? 'success' : 'error',
      }}
    >
      {appModel.isOffline
        ? <UiText className={styles.text} variant={'body1'} color={'red'}>{LangKey.OfflineGetOffline}</UiText>
        : <UiText className={styles.text} variant={'body1'} color={'green'}>{LangKey.OfflineGetOnline}</UiText>
      }
    </Confirm>
  );
});

export default OfflineToggle;
