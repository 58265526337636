import { appModel } from './models/App';
import { addParentField, getDataChanges, getRout } from './API';
import { DNewFG } from './models/FreeGeometries';
import { Stand } from './models/Stands';
import { NewStandFromEditor } from './views/editor/EditorMap';
import { DUnsavedNote } from './models/Notes';
import { ProjectsChangedData } from './models/Projects';
import { UtilsFile } from './utils/UtilsFile';
import moment from 'moment/moment';

export const LOCAL_OFFLINE_BACKUP = 'eTapio_eOffKIT_backup';

export type BackupData = { url: string, body: {}, method: 'POST' | 'PUT' | 'DELETE' }[];

export type BackupPayload = {
  data: BackupData,
  backupType: BackupType,
};
export enum BackupType {
  all = 1,
  failed= 2,
}

export default class Backup {
  constructor() {
  }

  sendAllRequest = (data: BackupData) => {
    return appModel.api.backup({ data: [...data], backupType: BackupType.all });
  }

  sendFailedRequest = (data: BackupData) => {
    appModel.api.backup({ data: [...data], backupType: BackupType.failed });
  }

  saveFile = async (data: BackupData) => {
    const date = moment().format('YYYYMMDD-hhmm');
    UtilsFile.save(JSON.stringify(data), `ForestKIT_Offline_backup_${date}`, 'json');
  }

  makeBody = (
    unsavedFG: DNewFG[],
    unsavedData?: {
      notes: DUnsavedNote[],
      projects: ProjectsChangedData,
    },
    unsavedStands?: {
      deletedStands: Stand[],
      addedStands: NewStandFromEditor[],
    },
  ) => {
    const payload: BackupData = [];
    if (unsavedStands) {
      if (unsavedStands.addedStands.length > 0) {
        for (const stand of unsavedStands.addedStands) {
          payload.push({
            url: process.env.REACT_APP_API_URL + '/stands/',
            body: stand,
            method: 'POST',
          });
        }
      }
      if (unsavedStands.deletedStands.length > 0) {
        payload.push({
          url: process.env.REACT_APP_API_URL + '/stands/bulk/',
          body: unsavedStands.deletedStands.map(s => s.id),
          method: 'DELETE',
        });
      }
    }
    if (unsavedFG.length) {
      unsavedFG.forEach((fg) => {
        payload.push({
          url: `${process.env.REACT_APP_API_URL}/dataSetFreeGeometry/${fg.id}`,
          body: fg.data,
          method: 'POST',
        });
      });
    }
    if (unsavedData) {
      const backupProjects = JSON.parse(JSON.stringify(unsavedData.projects));
      payload.push({
        url: process.env.REACT_APP_API_URL + '/dataSetChanged',
        body: { ...unsavedData, projects: getDataChanges(backupProjects) },
        method: 'POST',
      });
      for (const project in unsavedData.projects) {
        for (const element of unsavedData.projects[project]) {
          for (const addInstance of element.changedKeys) {
            if (addInstance.type === 'delete') {
              payload.push({
                url: process.env.REACT_APP_API_URL + '/' + getRout(addInstance.key) + addInstance.id,
                body: '',
                method: 'DELETE',
              });
            }
            if (addInstance.type === 'add') {
              payload.push({
                url: process.env.REACT_APP_API_URL + '/' + getRout(addInstance.key),
                body: { ...addParentField(element), ...addInstance.item },
                method: 'POST',
              });
            }
          }
        }
      }
    }
    return payload;
  }
}
