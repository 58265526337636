import * as React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { Theme } from '@mui/material';
import { allLanguages, lang, LangType } from '../../Lang';
// @ts-ignore
import { ReactComponent as IconEN } from './icons/en.svg';
// @ts-ignore
import { ReactComponent as IconFI } from './icons/fi.svg';
// @ts-ignore
import { ReactComponent as IconSE } from './icons/sv.svg';
// @ts-ignore
import { ReactComponent as IconES } from './icons/es.svg';
// @ts-ignore
import { ReactComponent as IconVN } from './icons/vn.svg';
// @ts-ignore
import { ReactComponent as IconPT } from './icons/pt.svg';
import Button from '../../uicomponents/Button';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles((theme: Theme) => createStyles({
  countryIcon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1),
  },
  languageSelect: {
    padding: '8px 16px',
  },
}));

const flags: { locale: LangType, icon: any }[] = [
  { locale: 'en', icon: IconEN },
  { locale: 'es', icon: IconES },
  { locale: 'fi', icon: IconFI },
  { locale: 'vn', icon: IconVN },
  { locale: 'sv', icon: IconSE },
  { locale: 'pt', icon: IconPT },
];

type Props = {
  withText?: boolean;
  withFlag?: boolean;
};

const LanguageSelect = (props: Props) => {
  const { withText, withFlag } = props;
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>  setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const onMenuClick = (item: { lang: LangType, label: string }) => () => {
    handleClose();
    if (item.lang !== lang.locale) {
      lang.change(item.lang);
    }
  };

  const createFlagIcon = (locale: string) => {
    const flag = flags.find(flag => flag.locale === locale);
    return flag ? React.createElement(flag.icon, { className: styles.countryIcon }) : null;
  };

  const icon = createFlagIcon(lang.locale);

  return (
    <>
      <Button
        className={styles.languageSelect}
        size={'small'}
        endIcon={<ArrowDropDown htmlColor={'#000'} />}
        onClick={handleClick}
        color={'inherit'}
      >
        {withFlag && icon} {withText && lang.label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {allLanguages.map(item => (
          <MenuItem
            dense
            key={item.lang}
            onClick={onMenuClick(item)}
            selected={item.lang === lang.locale}
          >
            <ListItemIcon style={{ minWidth: 36 }}>
              <>
                {createFlagIcon(item.lang)}
              </>
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

LanguageSelect.defaultProps = {
  withFlag: true,
};

export default LanguageSelect;
