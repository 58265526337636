import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import MapStandInfo from './MapStandInfo';
import StandMapBase from './StandMapBase';
import { MapElement } from '../../models/map/MapElement';

const useStyles = makeStyles(createStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  map: {
    flexGrow: 1,
  },
}));

const AppMap: React.FunctionComponent<{
  gpsRecording?: boolean,
  mapElements?: MapElement[],
}> = ({ gpsRecording, mapElements }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <StandMapBase gpsRecording={gpsRecording} mapElements={mapElements} />
      <MapStandInfo />
    </div>
  );
};

export default AppMap;
