import * as React from 'react';
import { ListItemTextProps as MuiListItemTextProps, default as MuiListItemText } from '@mui/material/ListItemText';
import { Utils } from '../Lang';

type ListItemTextProps = MuiListItemTextProps;

class ListItemText extends React.Component<ListItemTextProps> {
  constructor(props: ListItemTextProps) {
    super(props);
  }

  render() {
    const props = { ...this.props };
    return React.createElement(MuiListItemText, props, Utils.translateStringChildren(props.children));
  }
}

export default ListItemText;
