import * as React from 'react';
import { observer } from 'mobx-react';

import { appModel } from '../../models/App';
import Project from '../../models/Project';
import MapBtnLayersInfo from '../download/MapBtnLayersInfo';
import { translateUnit } from '../../models/DataNodeUtils';
import ProjectMenu from './ProjectMenu';
import { LangKey } from '../../LangKey';
import { ReactCheckbox } from '../../uicomponents/ReactCheckbox';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

type Props = {
  project: Project;
};

const ProjectListItem: React.FunctionComponent<Props> = observer((props) => {
  const { project } = props;
  const styles = useStyles();

  const projects = appModel.projects;

  const progressPercent = Math.round(project.standsCreationProgress * 100);
  const loaded = progressPercent === 100;
  /*  const secondary = project.standsMunicipality.length === 1 && project.standsMunicipality[0] === ''
      ? LangKey.ProjectsYouStandsCount.t({ count: project.standsCount })
      : `${project.standsMunicipality}, ${LangKey.ProjectsYouStandsCount.t({ count: project.standsCount })}`;*/

  return (
    <ListItem classes={{ root: styles.root }}>
      <ReactCheckbox
        model={project}
        onChange={() => projects.toggleProject(project)}
        checkChecked={() => projects.selectedIds.includes(project.id)}
        name={`project${project.id}`}
        label={''}
      />
      <ListItemText
        onClick={() => projects.showProject(project)}
        primary={project.name}
        secondary={
          loaded
            ? `${project.standsMunicipality} ${LangKey.ProjectsYouStandsCount.t({ count: project.standsCount })} (${translateUnit(LangKey.ProjectsArea.t({ area: project.area }))})`
            : LangKey.ProjectsLoading.t({ percent: progressPercent })
        }
      />
      <MapBtnLayersInfo model={project} />
      <ProjectMenu model={project} />
    </ListItem>
  );
});

export default ProjectListItem;
