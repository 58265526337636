import axios, { AxiosPromise, AxiosResponse, Canceler, Method } from 'axios';
import { computed, makeObservable } from 'mobx';
import { appModel, DPartialApp } from './models/App';
import { DUnsavedNote } from './models/Notes';
import { ProjectsChangedData } from './models/Projects';
import { ChangedData, DataNodePropValue, DDataNode, DescriptorType } from '@simosol/iptim-data-model';
import { DFreeGeometries, DFreeGeometry, DNewFG } from './models/FreeGeometries';
import { DescriptorComplex } from './models/DataNodeUtils';
import { Stand } from './models/Stands';
import { NewStandFromEditor } from './views/editor/EditorMap';
import { BackupPayload } from './Backup';

const DATA_CHANGES_CHUNK_SIZE = 1;

type ErrorData =  {[key: string]: string[]};

type APIResponseBaseError = {
  code?: string,
  data?: ErrorData,
  message?: string,
};

/**
 * base response from server
 */
type ApiResponseBase<S> =
  { success: true; data: S; structure?: DescriptorComplex} |
  { success: false; error: APIResponseBaseError};

export enum ErrorType {
  network = 'network',
  other = 'other',
  invalidSession = 'invalid session',
}

type ApiError = { type: ErrorType } & APIResponseBaseError;

/**
 * Response from server after handling all errors
 */
export interface ApiMethodResponse<S = {}> {
  data?: S;
  structure?: DescriptorComplex;
  error?: ApiError;
}

type Transformer<S, T> = (data: S) => T | void;

export type ApiMethodPromise<S> = Promise<ApiMethodResponse<S>> & {
  cancel: Canceler;
  onData: <T>(transformer: Transformer<S, T>) => ApiMethodPromise<T>;
};

type ApiMethodOptions = { noSid: boolean };

export default class API {
  constructor() {
    makeObservable(this);
  }

  @computed
  public get sid() { return appModel.localStorage.sid; }
  private setSid = (value: string | undefined) => { appModel.localStorage.setSid(value); };
  @computed
  public get userId() { return appModel.localStorage.userId; }
  private setUserId = (value: string | undefined) => { appModel.localStorage.setUserId(value); };
  /**
   * Base function for creating API methods
   */
  private _apiMethod = <T = {}>(url: string, method: Method, data?: {}, options?: ApiMethodOptions) => {
    const cancelSource = axios.CancelToken.source();
    const request: Promise<AxiosResponse<ApiResponseBase<T>>> = axios({
      url,
      method,
      data,
      cancelToken: cancelSource.token,
      headers: (!options || !options.noSid)
        ? {
          sid: this.sid,
          token: this.sid,
        }
        : undefined,
    });
    return this.createApiMethodPromise(this._apiResponse(request), cancelSource.cancel);
  }

  private _apiActionMethod = <T = {}>(action: string, method: Method, data?: {}, options?: ApiMethodOptions) =>
    this._apiMethod<T>(
      process.env.REACT_APP_API_URL! + '/' + action,
      method,
      data,
      options,
    )

  /**
   * Creates the promise, which handles server answer and errors
   */
  private _apiResponse = async <T>(req: AxiosPromise<ApiResponseBase<T>>) => {
    const res: ApiMethodResponse<T> = {};
    try {
      const reqRes = await req;
      const reqData: ApiResponseBase<T> = reqRes.data;
      if (reqData.success || reqRes.status === 200) {
        // @ts-ignore
        res.data = reqData.data ?? reqRes;
        // @ts-ignore
        if (reqData.structure) res['structure'] = reqData.structure;
      } else {
        res.error = { type: ErrorType.other, ...reqData.error };
      }
    } catch (e: any) {
      if (e.response) {
        const data:ApiResponseBase<T> = e.response.data;
        let type =  ErrorType.other;
        if (!data.success) {
          const code = data?.error?.code;
          // const status = e.response.status;
          if (code && code === 'IptimException.authInvalidSession') {
            type = ErrorType.invalidSession;
            this.setSid(undefined);
          }
          res.error = { type, ...data.error };
        }
      } else if (e.request) {
        res.error = {
          type: ErrorType.network,
          message: 'No connection',
        };
      } else {
        throw e;
      }
    }
    return res;
  }

  /**
   * Add new methods to api method promise
   * @param {Promise<ApiMethodResponse<S>>} promise
   * @param {Canceler} cancel
   * @returns {ApiMethodPromise<S>}
   * @private
   */
  public createApiMethodPromise = <S>(
    promise: Promise<ApiMethodResponse<S>>,
    cancel: Canceler,
  ) => {
    const tPromise = promise as ApiMethodPromise<S>;
    tPromise.onData = <T>(transformer: Transformer<S, T>) =>
      this.createApiMethodPromise<T>(
        promise.then((value) => {
          const transformedData = value.data ? transformer(value.data) : undefined;
          return {
            data: transformedData ? transformedData : undefined,
            structure: value.structure ?? undefined,
            error: value.error,
          };
        }),
        cancel,
      );
    tPromise.cancel = cancel;
    return tPromise;
  }

  // ---=== Add API methods here ===---

  sessionStart = (data: { client_id: string, username: string, password: string }) =>
    this._apiActionMethod<{ sessionID: string, userID: string }>('sessionStart', 'POST', data, { noSid: true })
      .onData((res) => {
        this.setSid(res.sessionID);
        this.setUserId(res.userID);
      })

  getData = (resKey?: string) => {
    const data = {};
    if (resKey) data['resKey'] = resKey;
    const route = appModel.isTapioIsEstates ? 'dataGetEstates' : 'dataGet';
    return this._apiActionMethod<DPartialApp>(route, 'POST', data);
  }

  backup = (par: BackupPayload) => {
    return this._apiActionMethod<{}>('users/offline-session-backup/', 'POST', par);
  }

  standGet = (id: string) => {
    return this._apiActionMethod<DDataNode>(`stands/${id}/`, 'GET');
  }

  dataGetFreeGeometry = () => {
    return this._apiActionMethod<DFreeGeometries>('dataGetFreeGeometry', 'GET');
  }
  dataGetStructure = () => {
    return this._apiActionMethod<{ data: DescriptorComplex }>('descriptors/0/', 'GET');
  }

  setGetFreeGeometry = (data: DNewFG) => {
    return this._apiActionMethod<DFreeGeometry>(
      `dataSetFreeGeometry/${data.id}`,
      'PUT',
      { ...data.data },
    );
  }

  getTilesCache = (
    stands: (string |number)[] | undefined,
    freeGeometryWorkSite: (string |number)[] | undefined,
    tileType: number,
  ) => {
    return axios.post<string[]>(
      process.env.REACT_APP_API_URL! + '/' + 'maps/tiles-cache/',
      { stands, freeGeometryWorkSite, tileType },
      {
        headers: {
          sid: this.sid,
          token: this.sid,
        },
      });
  }

  setDataChanged = async (data: { projects: ProjectsChangedData, notes: DUnsavedNote[] }) => {
    // First, we add new instances one by one, and save their real IDs to the local storage,
    // so that we can use them later to send the creation of child instances, or changes to them
    const addAllInstance = async () => {
      for (const project in data.projects) {
        const elements = data.projects[project].reverse();
        for (const element of elements) {
          for (const addInstance of element.changedKeys) {
            if (addInstance.type === 'delete') {
              await this.dataNodeDelete(`${getRout(addInstance.key)}${appModel.instanceControl.getId(addInstance.id) ?? addInstance.id}`);
            }
            if (addInstance.type === 'add') {
              if (appModel.instanceControl.getId(`${addInstance.item.uid}`)) return;
              const node = await this.dataNodeAdd(
                getRout(addInstance.key),
                { ...addParentField(element), ...addInstance.item },
              );
              if (node.data?.data.id) {
                appModel.instanceControl.setId(`${addInstance.item.uid}`, node.data?.data.id);
              }
            }
          }
        }
      }
    };

    await addAllInstance();

    const { projects, notes} = data;

    // send one times Notes without Projects
    this._apiActionMethod('dataSetChanged', 'POST', { notes, projects: {} });

    // get all changed projects ids
    const projectIds = Object.keys(projects);

    const allPromises: Promise<ApiMethodResponse>[] = [];

    // send projects one by one
    projectIds.map((id) => {
      const allChanges = projects[id];
      const chunks = Math.ceil(allChanges.length / DATA_CHANGES_CHUNK_SIZE);
      for (let step = 0; step < chunks; step += 1) {
        allPromises.push(this._apiActionMethod(
          'dataSetChanged',
          'POST',
          { projects: getDataChanges({ [id]: allChanges.slice(step * DATA_CHANGES_CHUNK_SIZE, (step + 1) * DATA_CHANGES_CHUNK_SIZE) }) }
        ))
      }
    });
    return Promise.all(allPromises);
  }

  public dataNodeAdd = async (
    route: string,
    data: DDataNode,
    standIds?: number[],
  ) => {
    const newData = getDataNodeForRequest(data);
    return this._apiActionMethod<{data: any}>(
      route,
      'POST',
      standIds ? { ...newData, stands: standIds } : newData,
    );
  }

  public dataNodeDelete = (route: string) =>
    this._apiActionMethod(route + '/', 'DELETE')

  removeStand = (standId: Stand['id']) =>
    this._apiActionMethod<{message: string}>(`stands/${standId}/`, 'DELETE')

  public deleteStandBulk = (stands: string[]) =>
    this._apiActionMethod<{}>('stands/bulk/', 'DELETE', { stands })

  public standAdd = (stand: NewStandFromEditor) =>
    this._apiActionMethod<{}>('stands/', 'POST', { ...stand })
}

const getDataNodeForRequest = (source: DDataNode) => {
  const newData: DDataNode = { ...source };
  delete newData['___changeProps'];
  // TODO: why ignore?!
  // @ts-ignore
  delete newData['uid'];

  for (const k in newData) {
    const item = newData[k];
    const dateItem = (item as DataNodePropValue<DescriptorType.date>);
    const year = dateItem.year;
    if (year) {
      newData[k] = `${dateItem.year}-${dateItem.month + 1}-${dateItem.date}`;
    }
  }
  return newData;
};

// TODO remove this very dirty hack!!!
export const getRout = (key: string): string => {
  const swap = (rout: string): string | undefined => swapArray.find(el => el.from === rout)?.to;
  const STRATUM = 'stratum';
  const DEADTREESTRATUM = 'deadtreestratum';
  const dontChange: string[] = [STRATUM, DEADTREESTRATUM];
  const swapArray: {from: string, to: string }[] = [
    { from: STRATUM, to: 'strata' },
    { from: DEADTREESTRATUM, to: 'deadtreestrata' },
  ];
  const name = key.toLowerCase();
  return dontChange.includes(name) ? `${swap(name)}/` : `${name}s/`;
};

export const getDataChanges = (proj: ProjectsChangedData) => {
  const projects = {};
  // now we can delete elements or make changes to existing ones
  for (const project in proj) {
    projects[project] = [];
    proj[project].reverse().map((el: ChangedData) => {
      const { changedKeys, position } = el;
      // change key id to uid
      const pos = position.map((p) => {
        const { id, ...other } = p;
        return { ...other, uid: id };
      });

      changedKeys.map(async (changedKey) => {
        if (changedKey.type === 'change') {
          const isDate = (): boolean => {
            if (!changedKey.value) return false;
            return changedKey.value['py/object'] === 'iptim.amf.SimpleDate';
          };
          const formatToMask = () => {
            if (isDate()) {
              // @ts-ignore
              return `${changedKey.value.year}-${changedKey.value.month + 1}-${changedKey.value.date}`;
            } else {
              return changedKey.value;
            }
          };

          projects[project].push({
            key: changedKey.key,
            newValue: formatToMask() ?? null,
            position: appModel.instanceControl.fixPosition(pos),
          });
        }
      });
    });
  }
  return projects;
};

export const addParentField = (item: ChangedData) => {
  const parentFields: { [key: string]: string } = {};
  for (const position of item.position) {
    const trueId = appModel.instanceControl.getId(position.id) ?? position.id;
    position.parentKey
      ? parentFields[position.parentKey.toLowerCase()] = trueId
      : parentFields['stand'] = trueId;
  }
  return parentFields;
};
