import * as React from 'react';
import { LangKey } from '../../LangKey';
import AppToolbarMenu from '../app/AppToolbarMenu';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import { LayersLoader } from '../../models/map/LayersLoader';
import Divider from '@mui/material/Divider';
import SelectAll from '@mui/icons-material/SelectAll';

type Props = {
  model: LayersLoader | undefined;
  isAllSelected: boolean,
  onSelectAll: () => void;
};

const DownloadToolbarMenu: React.FunctionComponent<Props> = (
  { model, onSelectAll, isAllSelected, children },
) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (el: Element | null) => {
    setAnchorEl(el);
  };

  const handleDownload = () => {
    if (model) model.downloadDialogOpened = true;
    setAnchorEl(null);
  };

  const haveSelectedStands = !model?.selectedIds.length;

  return (
    <AppToolbarMenu setAnchorEl={handleClick} anchorEl={anchorEl}>
      <MenuItem
        disabled={haveSelectedStands}
        onClick={haveSelectedStands ? undefined : handleDownload}
      >
        <ListItemIcon>
          <DownloadOutlined fontSize={'small'} />
        </ListItemIcon>
        <ListItemText primary={LangKey.ProjectsDownloadMap.t()}/>
      </MenuItem>
      {children}
      <Divider />
      <MenuItem
        onClick={onSelectAll}
      >
        <ListItemIcon>
          <SelectAll fontSize={'small'} />
        </ListItemIcon>
        <ListItemText
          primary={isAllSelected ? LangKey.OfflineUnselectAll.t() : LangKey.OfflineSelectAll.t()}
        />
      </MenuItem>
    </AppToolbarMenu>
  );
};

export default DownloadToolbarMenu;
