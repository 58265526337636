import { computed, makeObservable } from 'mobx';
import { appModel } from './App';
import { Stand } from './Stands';
import arraySearch from '../arraySearch';
import { DUnsavedNote, Notes } from './Notes';
import { DataNode, propsDisplayFiltered } from './DataNodeUtils';
import { DescriptorType } from '@simosol/iptim-data-model';

export interface DUnsavedTask {
  projectId: string;
  standId: string;
  uid: string;
  status: TaskStatus;
}

export enum TaskStatus {
  undefined = 0, // only in client
  computed = 1,
  planned  = 2,
  assigned = 3,
  ongoing  = 4,
  executed = 5,
  delayed  = 6,
  disabled  = 7,
}

export class Task {
  stand: Stand;
  operation: DataNode;
  notes: Notes;

  constructor(stand: Stand, operation: DataNode) {
    makeObservable(this);
    this.stand = stand;
    this.operation = operation;
    this.notes = new Notes(this);
  }

  get id() {
    return this.operation.id;
  }

  get status() {
    return getOperationStatus(this.operation) || TaskStatus.undefined;
  }

  set status(value: TaskStatus) {
    const prop = getOperationStatusProp(this.operation);
    if (prop) prop.value = value;
  }

  @computed
  get version() { return this.stand.version; }

  @computed
  get props() {
    const standPropKeys = !appModel.isTapio
      ? ['A', 'plantingDate', 'oid', 'area']
      : ['number', 'extension', 'area', 'dataDate', 'mainGroup', 'subGroup', 'mainTreeSpecies', 'developmentClass', 'accessibility', 'fertilityClass', 'soilType', 'drainageState', 'ditchingYear', 'quality', 'publicText', 'ownText', 'goalText', 'selectedTreatment', 'optimizedTreatment'];
    const props = propsDisplayFiltered(this.stand.props).filter(
      prop => standPropKeys.indexOf(prop.key) !== -1,
    );
    const assignee = this.operation.getProp('assignee', DescriptorType.dynamicCategory);
    if (assignee) {
      props.unshift(assignee);
    }
    const dateStart = this.operation.getProp('date_planned', DescriptorType.date);
    if (dateStart) props.push(dateStart);
    const dateEnd = this.propDateEnd;
    if (dateEnd) props.push(dateEnd);
    return props;
  }

  @computed
  get nameArea() {
    return this.name + ' ' + this.stand.areaWithUnit;
  }

  get name() {
    return this.operation.listPrimaryPropDisplayValue
      ? this.operation.listPrimaryPropDisplayValue
      : this.type?.displayValue;
  }

  @computed
  get propDateEnd() {
    return this.operation.getProp('date_executed', DescriptorType.date);
  }
  @computed
  get propDateStart() {
    return this.operation.getProp('dateStart', DescriptorType.date);
  }
  @computed
  get assignee() {
    return this.operation.getProp('assignee', DescriptorType.dynamicCategory);
  }
  @computed
  get project() {
    return this.stand.project;
  }

  @computed
  get dateEnd() {
    const prop = this.propDateEnd;
    if (!prop) return '';
    return prop.displayValue;
  }

  @computed
  get type() {
    return this.operation.getProp('type', DescriptorType.category);
  }

  @computed
  get propAssigned() {
    return this.operation.getValue('assignee', DescriptorType.dynamicCategory);
  }

  @computed
  get yearPlanned() {
    return this.operation.getValue('yearPlanned', DescriptorType.numeric);
  }

  getNote = (noteId: string) => {
    return this.notes.getNote(noteId);
  }

  joinSecondary = (array: (string | undefined)[]) => array.filter(Boolean).join(', ');
}

export class Tasks {
  init = () => {};

  constructor() {
    makeObservable(this);
  }

  @computed
  get tasks() {
    let res: Task[] = [];
    appModel.projects.forEach((project) => {
      project.stands.forEach((stand) => {
        res = res.concat(this._getTasksFromStand(stand));
      });
    });
    return res;
  }

  get = (taskId: string) => {
    return arraySearch('id', taskId, this.tasks);
  }

  getNote = (noteId: string) => {
    for (const task of this.tasks) {
      const note = task.getNote(noteId);
      if (note && note.id === noteId) return note;
    }
    return undefined;
  }

  @computed
  get currentTask() {
    const pg = appModel.browser.page;
    if (pg.p === 'task') {
      const task = this.get(pg.p1);
      if (task) return task;
    }
    return undefined;
  }

  private _getTasksFromStand = (stand: Stand): Task[] => {
    const operations = stand.propOperations;
    if (!operations) return [];
    const res: Task[] = [];
    const allowedStatuses = !appModel.isTapio
      ? [TaskStatus.assigned, TaskStatus.ongoing, TaskStatus.executed]
      : [TaskStatus.computed, TaskStatus.planned, TaskStatus.assigned, TaskStatus.ongoing, TaskStatus.executed];
    for (const operation of operations.nodes) {
      const status = getOperationStatus(operation);
      if (status === undefined) continue;
      if (allowedStatuses.indexOf(status) !== -1) {
        res.push(new Task(stand, operation));
      }
    }
    return res;
  }

  getUnsavedNotes = (): DUnsavedNote[] => {
    let notes: DUnsavedNote[] = [];
    for (const task of this.tasks) {
      notes = notes.concat(task.notes.getUnsavedNotes());
    }
    return notes;
  }
}

const getOperationStatusProp = (operation: DataNode) => {
  return operation.getProp('status', DescriptorType.category);
};

const getOperationStatus = (operation: DataNode) => {
  const prop = getOperationStatusProp(operation);
  return prop ? prop.value : undefined;
};

export const getOperationAssigned = (operation: DataNode) => {
  const prop = operation.getProp('assigned', DescriptorType.category);
  return prop ? prop.value : undefined;
};
