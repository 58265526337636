import * as React from 'react';
import { Autocomplete, Theme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { KeysOfType } from '@simosol/forms/lib/FormInput';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputContainer: {
      width: 250,
      padding: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    colored: {
      backgroundColor: '#fff',
      borderRadius: theme.spacing(0.5),
    },
  }),
);

type Props<M> = {
  model: M;
  id: KeysOfType<M, string>;
  options: string[];
  placeholder?: string;
  isExpanded?: boolean;
};

const SearchField = <M, >(props: Props<M>) => {
  const { model, id, options, placeholder, isExpanded } = props;
  const styles = useStyles();
  const getMValue = (model: M) => model[id] as unknown as string;

  const [inputValue, setInputValue] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);
  const [searchName, setSearchName] = React.useState<string | null>(getMValue(model));

  const setModelValue = (value: string | null) => {
    model[id] = value as unknown as M[KeysOfType<M, string>];
    isExpanded ? setOpen(false) : undefined;
  };

  const handleChange = (_: React.SyntheticEvent, newValue: string | null) => {
    setSearchName(newValue ?? '');
    isExpanded ? setOpen(true) : setOpen(false);
  };

  const handleInputChange = (_: React.SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
    (newInputValue != null && newInputValue.length > 0)
      ? setOpen(true)
      : setOpen(false);
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setSearchName(inputValue);
    }
  };

  React.useEffect(() => { setModelValue(searchName); }, [searchName]);

  return (
    <Autocomplete
      open={open}
      onClose={() => setOpen(false)}
      freeSolo={true}
      options={options}
      value={searchName}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={handleInputChange}
      className={styles.inputContainer}
      onKeyPress={handleEnter}
      renderInput={params => (
        <TextField
          {...params}
          variant={'outlined'}
          label={placeholder ?? ''}
          type={'text'}
          size={'small'}
          className={styles.colored}
        />
      )}
    />
  );
};

export default SearchField;
