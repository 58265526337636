import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  createStyles({
    spacer: {
      flexGrow: 1,
    },
  }),
);

const Spacer: React.FunctionComponent = () => {
  const classes = useStyles();
  return <div className={classes.spacer} />;
};

export default Spacer;
