import * as React from 'react';
import { observer } from 'mobx-react-lite';

import { appModel } from '../../models/App';
import AppToolbar from '../app/appBar/AppToolbar';
import DownloadToolbarMenu from '../download/DownloadToolbarMenu';
import DownloadDialog from '../download/DownloadDialog';
import ProjectListItem from './ProjectListItem';
import { LangKey } from '../../LangKey';
import DataPreloader from '../../uicomponents/DataPreloader';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import ProjectSearch from './ProjectSearch';
import { HBox } from '../../uicomponents/Boxes';
import UiText from '../../uicomponents/UiText';

const useStyles = makeStyles((theme: Theme) => createStyles({
  pointer: {
    paddingLeft: theme.spacing(.5),
    cursor: 'pointer',
    alignItems: 'center',
    height: theme.spacing(8),
  },
}));

const Projects = observer(() => {
  const projects = appModel.projects;
  const styles = useStyles();

  return (
    <>
      <AppToolbar title={LangKey.ProjectsTitle} button={{ type: 'menu' }}>
        <>
          <DownloadToolbarMenu
            model={projects.layersLoader}
            isAllSelected={projects.isAllEstatesSelected}
            onSelectAll={projects.onSelectAll}
          />
        </>
      </AppToolbar>
      <DataPreloader>
        {appModel.isTapioIsEstates && <ProjectSearch model={projects} />}
        <HBox gap={8} onClick={projects.onSelectAll} className={styles.pointer}>
          <Checkbox
            checked={projects.isAllEstatesSelected}
            color="primary"
          />
          <UiText variant={'subtitle1'}>{LangKey.OfflineSelectAll.t()}</UiText>
        </HBox>
        <Divider />
        {projects.filteredProjects.map(project => (
          <ProjectListItem project={project} key={project.id}/>
        ))}
        <DownloadDialog model={projects.layersLoader} />
      </DataPreloader>
    </>
  );
});

export default Projects;
