import { BaseLayer } from '@simosol/stands-map';
import { LangKey } from '../../../LangKey';

const baseLayersETapio: BaseLayer [] = [
  {
    name: LangKey.MapBaseLayersLoisto,
    style: 'temp/loisto_styles_v3.json',
  },
];

export default baseLayersETapio;
