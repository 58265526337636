export class UtilsFile {
  static save = (text: string, fileName: string, fileExtension: string = 'xml') => {
    const blob = new Blob([text], { type: 'text/plain' });
    UtilsFile.fileSave(
      window.URL.createObjectURL(blob),
      `${fileName}.${fileExtension}`,
    );
  }

  static fileSave = (url: string, name: string) => {
    const el = document.createElement('a');
    el.href = url;
    el.download = name;
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
  }
}
