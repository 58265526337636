import * as React from 'react';
import { isCordova } from '../index';
import { action, makeObservable, observable } from 'mobx';

export enum PositionWatcherError {
  PERMISSION_DENIED,
  POSITION_UNAVAILABLE,
  TIMEOUT,
  OTHER,
}

export default class PositionWatcher {
  @observable.ref
  position?: GeolocationPosition;
  @observable.ref
  error?: { code: PositionWatcherError, message?: string };

  @observable
  rotation?: number;
  @observable rotationEnabled = false;

  constructor() {
    makeObservable(this);
  }

  private _watchId?: number;
  private _startWatchingPosition = () => {
    this.clearWatch();
    if (isCordova) {
      cordova.plugins.diagnostic.registerLocationStateChangeHandler!((state) => {
        if (!this._stateIsAvailable(state)) {
          this._setError(PositionWatcherError.POSITION_UNAVAILABLE);
        }
      });
    }
    this._watchId = navigator.geolocation.watchPosition(
      this._onPositionChanged,
      this._onPositionError,
      { enableHighAccuracy: true },
    );
    let event = undefined;
    if ('ondeviceorientationabsolute' in window) {
      event = 'deviceorientationabsolute';
      this.rotationEnabled = true;
    } else if ('ondeviceorientation' in window) {
      event = 'deviceorientation';
    }
    if (event) {
      window.addEventListener(
        event,
        this._onDeviceOrientationChanged as EventListener,
        true,
      );
    }
  }

  private _stateIsAvailable = (state: string) => {
    return (device.platform === 'Android' && state !== cordova.plugins.diagnostic.locationMode.LOCATION_OFF) ||
      (device.platform === 'iOS') && (state === cordova.plugins.diagnostic.permissionStatus.GRANTED ||
        state === cordova.plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE
      );
  }

  private _onDeviceOrientationChanged = (event: DeviceOrientationEvent) => {
    if (event.absolute) {
      this.rotationEnabled = true;
      if (event.alpha !== null) {
        this.rotation = 360 - event.alpha;
      }
    }
  }

  private _onPositionChanged = (position: GeolocationPosition) => {
    this._setPosition(position);
  }
  private _onPositionError = (e: GeolocationPositionError) => {
    let err = undefined;
    switch (e.code) {
      case 1:
        err = PositionWatcherError.PERMISSION_DENIED;
        break;
      case 2:
        err = PositionWatcherError.POSITION_UNAVAILABLE;
        break;
      case 3:
        err = PositionWatcherError.TIMEOUT;
        break;
      default:
        err = PositionWatcherError.OTHER;
    }
    this._setError(err);
  }
  @action
  private _setError = (e: PositionWatcherError, message?: string) => {
    this.clearWatch();
    this.position = undefined;
    this.error = { message, code: e };
    if (isCordova) {
      cordova.plugins.diagnostic.registerLocationStateChangeHandler!((state) => {
        if (this._stateIsAvailable(state)) {
          this.watchPosition();
        }
      });
    }
  }
  @action
  private _setPosition = (p: GeolocationPosition) => {
    this.position = p;
    this.error = undefined;
  }

  watchPosition = () => {
    this.error = undefined;
    this.clearWatch();

    if (isCordova) {
      const diagnostic = cordova.plugins.diagnostic;
      const checkEnabledAndStart = () => diagnostic.isLocationEnabled!(
        (enabled) => {
          if (enabled) {
            this._startWatchingPosition();
          } else {
            this._setError(PositionWatcherError.POSITION_UNAVAILABLE);
          }
        },
        (error) => {
          this._setError(PositionWatcherError.OTHER, error);
        },
      );

      diagnostic.isLocationAuthorized!(
        (authorized) => {
          if (authorized) {
            checkEnabledAndStart();
          } else {
            diagnostic.requestLocationAuthorization!(
              (status) => {
                if (
                  status === diagnostic.permissionStatus.GRANTED
                  || status === diagnostic.permissionStatus.GRANTED_WHEN_IN_USE) {
                  checkEnabledAndStart();
                } else {
                  this._setError(PositionWatcherError.PERMISSION_DENIED);
                }
              },
              (authRequestError) => {
                this._setError(PositionWatcherError.OTHER, authRequestError);
              },
              cordova.plugins.diagnostic.locationAuthorizationMode.ALWAYS,
            );
          }
        },
        (authError) => {
          this._setError(PositionWatcherError.OTHER, authError);
        });
    } else {
      this._startWatchingPosition();
    }
  }

  clearWatch = () => {
    window.removeEventListener('deviceorientationabsolute', this._onDeviceOrientationChanged as EventListener);
    if (this._watchId) {
      navigator.geolocation.clearWatch(this._watchId);
      this._watchId = undefined;
    }
    if (isCordova) {
      const diagnostic = cordova.plugins.diagnostic;
      // TODO PR in github to add false to parameters
      diagnostic.registerLocationStateChangeHandler!(false as any);
    }
  }
}

type PositionWatcherProps<T> = T & { positionWatcher: PositionWatcher };

export const withPositionWatcher = <PropsT>(
  WrappedComponent: React.ComponentType<PositionWatcherProps<PropsT>>,
) =>
  (class extends React.Component<PropsT> {
    private _watcher: PositionWatcher = new PositionWatcher();

    componentDidMount() {
      this._watcher.watchPosition();
    }

    componentWillUnmount() {
      this._watcher.clearWatch();
    }

    render() {
      const newProps = { ...this.props as {} } as PositionWatcherProps<PropsT>;
      newProps.positionWatcher = this._watcher;
      return React.createElement<PositionWatcherProps<PropsT>>(WrappedComponent, newProps);
    }
  });
