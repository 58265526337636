import * as React from 'react';
import * as turf from '@turf/turf';
import { appModel } from '../../../models/App';
import { FreeGeometry } from '../../../models/FreeGeometries';
import { Map } from '@simosol/mapbox-gl-js-offline';

const WORK_SITE_ALL_POINT = 'work_site_all_points';
const WORK_SITE_ALL_LINES = 'work_site_all_lines';
const WORK_SITE_ALL_POLYGONS = 'work_site_all_polygons';

const useFreeGeometries = (prop: {
  map: Map | undefined
  mapGeometry?: FreeGeometry;
  baseLayerChangedNum: number;
}) => {
  const { map, mapGeometry, baseLayerChangedNum } = prop;

  React.useEffect(
    () => {
      const freeGeometries = !appModel.onlyAssigned
        ? appModel.freeGeometries.freeGeometries
        : appModel.freeGeometries.freeGeometries.filter(fg => fg.assignedToMe);
      if (!map || !baseLayerChangedNum || !freeGeometries.length) return;

      const points = freeGeometries.filter((ws) => {
        return ws.geoJSONToMap?.geometry.type === 'Point' || ws.geoJSONToMap?.geometry.type === 'MultiPoint';
      });
      const lines = freeGeometries.filter((ws) => {
        return ws.geoJSONToMap?.geometry.type === 'LineString' || ws.geoJSONToMap?.geometry.type === 'MultiLineString';
      });
      const polygon = freeGeometries.filter((ws) => {
        return ws.geoJSONToMap?.geometry.type === 'Polygon' || ws.geoJSONToMap?.geometry.type === 'MultiPolygon';
      });

      if (mapGeometry) {
        map.fitBounds(
          turf.bbox(mapGeometry.geoJSONToMap) as [number, number, number, number],
          { padding: 20, linear: false, maxZoom: 18 },
        );
      }

      map.addLayer({
        id: WORK_SITE_ALL_POLYGONS,
        type: 'fill',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: polygon.map((el) => {
              const newGeoJSON: any = { ...el.geoJSONToMap };
              newGeoJSON.id = el.id;
              newGeoJSON.properties = {
                fillColor: el.assignedToMe ? '#fa2a2a' : '#838282',
              };
              return newGeoJSON;
            }),
          },
        },
        layout: {},
        paint: {
          'fill-color': ['get', 'fillColor'],
          'fill-opacity': 0.4,
        },
      });
      map.addLayer({
        id: WORK_SITE_ALL_LINES,
        type: 'line',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: lines.map((el) => {
              const newGeoJSON: any = { ...el.geoJSONToMap };
              newGeoJSON.id = el.id;
              newGeoJSON.properties = {
                lineColor: el.assignedToMe ? '#fa2a2a' : '#838282',
              };
              return newGeoJSON;
            }),
          },
        },
        layout: {
          'line-cap': 'butt',
          'line-join': 'round',
        },
        paint: {
          'line-color': ['get', 'lineColor'],
          'line-width': 10,
        },
      });
      map.addLayer({
        id: WORK_SITE_ALL_POINT,
        type: 'circle',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: points.map((el) => {
              const newGeoJSON: any = { ...el.geoJSONToMap };
              newGeoJSON.id = el.id;
              newGeoJSON.properties = {
                circleColor: el.assignedToMe ? '#fa2a2a' : '#838282',
              };
              return newGeoJSON;
            }),
          },
        },
        paint: {
          'circle-radius': 10,
          'circle-color': ['get', 'circleColor'],
        },
      });

      const clearLayer = (name: string) => {
        if (map.getLayer(name)) { map.removeLayer(name); }
        if (map.getSource(name)) { map.removeSource(name); }
      };

      return () => {
        clearLayer(WORK_SITE_ALL_POINT);
        clearLayer(WORK_SITE_ALL_LINES);
        clearLayer(WORK_SITE_ALL_POLYGONS);
      };
    },
    [map, baseLayerChangedNum, mapGeometry, appModel.onlyAssigned],
  );
};

export default useFreeGeometries;
