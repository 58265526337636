import { computed, makeObservable } from 'mobx';
import { appModel } from './App';
import { Page } from '../Browser';

export default class Map {
  private _backPage?: Page;

  constructor() {
    makeObservable(this);
  }

  @computed
  get currentProject() {
    const page = appModel.browser.page;
    if (page.p === 'map' && page.p1 === 'project') {
      const project = appModel.projects.get(page.p2);
      if (project && project.allStandsCreated) return project;
    }
    return undefined;
  }

  @computed
  get currentStand() {
    const project = this.currentProject;
    if (!project) return undefined;
    const page = appModel.browser.page;
    if (page.p === 'map') {
      if (page.p3 !== undefined) {
        return project.getStand(page.p3);
      }
    }
    return undefined;
  }
  @computed
  get currentGeometries() {
    const page = appModel.browser.page;
    if (page.p === 'map' && page.p1 === 'freeGeometry') {
      if (page.p2 !== undefined) {
        return appModel.freeGeometries.freeGeometries.find(fg => `${fg.id}` === page.p2);
      }
    }
    return undefined;
  }

  showMap = (projectId: string, standId?: string) => {
    this._backPage = appModel.browser.page;
    const newPage: Page = { p: 'map', p1: 'project', p2: projectId };
    if (standId) {
      newPage.p3 = standId;
    }
    appModel.browser.page = newPage;
  }

  back = () => {
    appModel.browser.page = this._backPage || { p: '' };
  }
}
