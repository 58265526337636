import * as React from 'react';
import { observer } from 'mobx-react';
import PositionWatcher, { withPositionWatcher } from '../PositionWatcher';
import { autorun, IReactionDisposer } from 'mobx';
import { LangKey } from '../../LangKey';
import FormTextField from '../../uicomponents/forms/FormTextField';
import { Form } from '@simosol/forms/lib/forms';
import { DEditableNote } from '../../models/Notes';

type NoteEditProps = {
  form: Form<DEditableNote>;
};

type Props = NoteEditProps & { positionWatcher: PositionWatcher; };

@observer
class NoteEditForm extends React.Component<Props> {
  private _disposer?: IReactionDisposer;

  componentDidMount() {
    this._disposer = autorun(() => {
      const position = this.props.positionWatcher.position;
      const form = this.props.form;

      if (position) {
        form.setValue('gpsCoords', position.coords.latitude + ',' + position.coords.longitude);
      } else {
        const gpsCoords = form.getValue('gpsCoords');
        if (gpsCoords === undefined) form.setValue('gpsCoords', '');
      }
    });
  }

  componentWillUnmount() {
    if (this._disposer) this._disposer();
  }

  private _formatGpsCoords = (value: string | undefined) => {
    if (!value) return '';
    const parts = value.split(',');
    const lat = Number(parts[0]);
    const long = Number(parts[1]);
    const pow = 1000;
    return Math.round(lat * pow) / pow + ',' + Math.round(long * pow) / pow;
  }

  render() {
    const mForm = this.props.form;
    return (
      <div style={{ padding: 12, maxWidth: 500 }}>
        <div>
          <FormTextField
            field={'text'}
            model={mForm}
            label={LangKey.NotesMessage.t()}
            multiline
          />
        </div>
        <div>
          <FormTextField
            field={'gpsCoords'}
            model={mForm}
            label={LangKey.NotesGps.t()}
            disabled
            formatFunction={this._formatGpsCoords}
          />
        </div>
        <div>
          <FormTextField
            field={'gpsAccuracy'}
            model={mForm}
            label={LangKey.NotesAccuracy.t()}
            disabled
          />
        </div>
      </div>
    );
  }
}

export default withPositionWatcher(NoteEditForm);
