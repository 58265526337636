import * as React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import UiText from '../../uicomponents/UiText';
import { MapOutlined } from '@mui/icons-material';
import { getLayersLang } from './Layers';
import { LangKey } from '../../LangKey';
import { observer } from 'mobx-react';

type Props = {
  model: {
    layersDb: number[];
  };
};

const MapBtnLayersInfo: React.FunctionComponent<Props> = observer(({ model }) => {
  const layers = getLayersLang();
  const layersNames = layers
    .filter(v => model.layersDb.includes(v.id))
    .map(v => v.name);

  const mapBtnColor = layersNames.length ? 'success' : undefined;

  const tooltipText = layersNames.length ? LangKey.OfflineMapLoadedWithLayers : LangKey.OfflineNoMapLoaded;

  return (
    <Tooltip
      title={(
        <>
          <UiText>{tooltipText}</UiText>
          {layersNames.map((v, k) => (<UiText key={k} disableTranslation>{v}</UiText>))}
        </>
      )}
    >
      <IconButton>
        <MapOutlined color={mapBtnColor} />
      </IconButton>
    </Tooltip>
  );
});

export default MapBtnLayersInfo;
