import * as React from 'react';
import {
  autorun as mobxAutorun,
  reaction as mobxReaction,
  IReactionDisposer,
  when as mobxWhen,
  IReactionPublic, Lambda, IWhenOptions, IAutorunOptions, IReactionOptions,
} from 'mobx';
import { observer } from 'mobx-react';

class Reactions {
  private _disposers: IReactionDisposer[] = [];
  dispose = () => {
    for (const disposer of this._disposers) {
      disposer();
    }
  }
  autorun = (view: (r: IReactionPublic) => unknown, opts?: IAutorunOptions) => {
    this._disposers.push(mobxAutorun(view, opts));
  }
  when = (predicate: () => boolean, effect: Lambda, opts?: IWhenOptions) => {
    this._disposers.push(mobxWhen(predicate, effect, opts));
  }
  reaction = <T, FireImmediately extends boolean = false>(
    expression: (r: IReactionPublic) => T,
    effect: (arg: T, prev: FireImmediately extends true ? T | undefined : T, r: IReactionPublic) => void,
    opts?: IReactionOptions) => {
    this._disposers.push(mobxReaction(expression, effect, opts));
  }
}

export type WithReactionsProps = { reactions: Reactions };
const withReactions =
  <PropsT extends {}>(Source: React.ComponentType<WithReactionsProps & PropsT>): React.ComponentType<PropsT> => {
    const ObservedSource = observer(Source);
    return class extends React.Component<PropsT> {
      private _reactions = new Reactions();
      componentWillUnmount() {
        this._reactions.dispose();
      }
      render() {
        return <ObservedSource reactions={this._reactions} {...this.props}/>;
      }
    };
  };
export default withReactions;
