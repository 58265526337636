import * as React from 'react';
import * as turf from '@turf/turf';
import { observer } from 'mobx-react-lite';
import { MapButton, Stand as MapStand } from '@simosol/stands-map';
import { Map } from '@simosol/mapbox-gl-js-offline';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ZoomIn from '@mui/icons-material/ZoomIn';
import IconButton from '@mui/material/IconButton';
import { Stand } from '../../models/Stands';
import { FreeGeometry } from '../../models/FreeGeometries';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(8),
    backgroundColor: '#fff',
    borderRadius: theme.spacing(3),
  },
}));

type ZoomToSelectedStands = {
  stands: MapStand[];
  map?: Map;
  currentStand?: Stand,
  mapGeometry?: FreeGeometry,
};

const ZoomToSelectedStands = observer((props: ZoomToSelectedStands) => {
  const { map, stands, currentStand, mapGeometry } = props;
  const style = useStyles();

  let boundedStands: MapStand[];
  const filteredStands = stands.filter(s => !s.disabled);
  if (filteredStands.length === 0) {
    const curMapStand = stands.find(s => s.id === currentStand?.id);
    if (curMapStand) {
      boundedStands = [curMapStand];
    } else {
      boundedStands = stands;
    }
  } else {
    boundedStands = filteredStands;
  }

  // Don't show zoom button, if no stands
  if (boundedStands.length === 0 && !mapGeometry) return null;

  const handleClick = () => {
    if (mapGeometry) {
      map?.fitBounds(
        turf.bbox(mapGeometry.geoJSONToMap) as [number, number, number, number],
        { padding: 20, linear: false, maxZoom: 18 },
      );
      return;
    }
    map?.fitBounds(
      turf.bbox(turf.combine(turf.featureCollection(
        [...boundedStands.map(s => s.geoJSON)]) as any)) as [number, number, number, number],
      { padding: 20, linear: false },
    );
  };

  return (
    <MapButton className={style.root}>
      <IconButton onClick={handleClick} size="large">
        <ZoomIn />
      </IconButton>
    </MapButton>
  );
});

export default ZoomToSelectedStands;
