import * as React from 'react';
import { KeysOfType } from '@simosol/forms/lib/FormInput';
import { Checkbox, FormControlLabel } from '@mui/material';
import { observer } from 'mobx-react';

type Props<T, V> = {
  model: T;
  listId: KeysOfType<T, V[]>;
  value: V;
  label: string;
  onChange: (value: V) => void,
  disabled?: boolean;
};

const FormCheckbox = observer(<T, V>(props: Props<T, V>): React.ReactElement | null => {
  const { model, listId, value, label, onChange, disabled } = props;
  const checked = (model[listId] as any).includes(value);
  return (
    <FormControlLabel
      control={(
        <Checkbox checked={checked} onChange={() => onChange(value)} name={label} disabled={disabled} />
      )}
      label={label}
    />
  );
});

export default FormCheckbox;
