import { LangKey } from '../../LangKey';
import { BaseLayer } from '@simosol/stands-map';

const baseLayers: BaseLayer [] = [
  {
    name: LangKey.MapBaseLayersOsm,
    style: {
      version: 8,
      sources: {
        'raster-tiles': {
          type: 'raster',
          tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
          tileSize: 256,
        },
      },
      layers: [
        {
          id: 'simple-tiles',
          type: 'raster',
          source: 'raster-tiles',
          minzoom: 0,
          maxzoom: 22,
        },
      ],
    },
  },
  {
    name: LangKey.MapBaseLayersLoisto,
    style: 'temp/loisto_styles_v3.json',
  },
  {
    name: LangKey.MapBaseLayersMbStreets,
    style: 'mapbox://styles/mapbox/streets-v11',
  },
  {
    name: LangKey.MapBaseLayersMpSatellite,
    style: 'mapbox://styles/mapbox/satellite-v9',
  },
  {
    name: LangKey.MapBaseLayersMpTerrain,
    style: 'mapbox://styles/mapbox/outdoors-v10',
  },
  {
    name: LangKey.MapBaseLayersMpLight,
    style: 'mapbox://styles/mapbox/light-v10',
  },
  {
    name: LangKey.MapBaseLayersMpDark,
    style: 'mapbox://styles/mapbox/dark-v10',
  },
];

export default baseLayers;
