import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import AppBar from './appBar/AppBar';
import AppDrawer from './AppDrawer';
import { appModel, UserRole } from '../../models/App';
import UiText from '../../uicomponents/UiText';
import Tasks from '../tasks/Tasks';
import Projects from '../projects/Projects';
import Project from '../projects/Project';
import Task from '../task/Task';
import MainMenu from './MainMenu';
import Settings from '../settings/Settings';
import DynamicElement from '../stands/DynamicElement';
import { ResultSnackBarStyled } from './ResultSnackBar';
import AppMap from '../map/AppMap';
import FreeGeometries from '../freeGeometry/FreeGeometries';
import FreeGeometry from '../freeGeometry/FreeGeometry';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@mui/styles';
import { StaticConfirmStyled } from './StaticConfirm';
import MapElementsMap from '../mapElements/MapElementsMap';
import Editor from '../editor/Editor';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { VBox } from '../../uicomponents/Boxes';
import { LangKey } from '../../LangKey';

const useStyles = makeStyles((theme: Theme) => createStyles({
  contentContainer: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.appDrawer.width,
    },
    height: '100%',
  },
}));

const App = observer(() => {
  const styles = useStyles();

  const pg = appModel.browser.page;
  let content = <UiText>Page not found {window.location.hash}</UiText>;

  if (
    (pg.p === 'tasks' || (pg.p === '' && appModel.userRole === UserRole.assignee))
    && !appModel.isTapioIsEstates
  ) {
    content = <Tasks/>;
  } else if (pg.p === 'task') {
    content = <Task />;
  } else if (pg.p === 'freeGeometries') {
    content = <FreeGeometries />;
  } else if (pg.p === 'freeGeometry') {
    content = <FreeGeometry />;
  } else if (pg.p === 'map' && pg.p1 === 'gps') {
    content = <MapElementsMap />;
  } else if (
    pg.p === 'projects' ||
    (
      pg.p === '' &&
      (appModel.userRole === UserRole.manager || appModel.userRole === UserRole.auditor)
    )
  ) {
    content = <Projects />;
  } else if (pg.p === 'project') {
    content = <Project />;
  } else if (pg.p === 'stand') {
    content = <DynamicElement />;
  } else if (pg.p === 'settings') {
    content = <Settings />;
  } else if (pg.p === 'map') {
    content = <AppMap />;
  } else if (pg.p === 'editor') {
    content = <Editor page={pg} />;
  }

  return (
    <>
      <AppBar />
      <AppDrawer>
        <MainMenu page={pg} />
      </AppDrawer>
      <div
        className={styles.contentContainer}
        style={{ paddingTop: appModel.toolBarHeight }}
      >
        {content}
      </div>
      <StaticConfirmStyled />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={false}
      >
        <VBox align="center">
          <CircularProgress color="inherit" />
          <UiText variant="subtitle1">{LangKey.OfflineSaving}</UiText>
        </VBox>
      </Backdrop>
      <ResultSnackBarStyled/>
    </>
  );
});

export default App;
