import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { default as MuiAppBar } from '../../../../node_modules/@mui/material/AppBar/AppBar';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

const styles = (theme: Theme) => createStyles({
  appBar: {
    // height: theme.appBar.height,
    backgroundColor: theme.palette.primary[900],
    // borderBottom: '1px solid rgba(0,0,0,0.15)',
    paddingLeft: 0,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.appDrawer.width,
    },
  },
});

type AppBarProps = {} & WithStyles<typeof styles>;

type Content = { children: React.ReactNode | null };
let content: Content = { children: null };
content = observable(content, undefined, { deep: false });

@observer
class AppBar extends React.Component<AppBarProps> {
  render() {
    const { classes } = this.props;
    return (
      <MuiAppBar elevation={0} position={'fixed'} className={classes.appBar}>
        <>
          {content.children}
        </>
      </MuiAppBar>
    );
  }
}

export default withStyles(styles)(AppBar);

export class AppBarContent extends React.Component {
  componentDidMount() {
    content.children = this.props.children;
  }
  componentDidUpdate = this.componentDidMount;
  componentWillUnmount() {
    content.children = null;
  }
  render() {
    return null;
  }
}
