import {
  DataNode as DataModelDataNode,
  DataNodeOptions,
  DataNodeProp as DMDataNodeProp,
  DataNodePropCategory as DMDataNodePropCategory,
  DataNodePropComplex as DMDataNodePropComplex,
  DataNodePropDate as DMDataNodePropDate,
  DataNodePropDynamicCategory as DMDataNodePropDynamicCategory,
  DataNodePropNumber as DMDataNodePropNumber,
  DataNodePropText as DMDataNodePropText,
  DDataNode,
  DESCRIPTOR_TYPE_PROP,
  DescriptorComplex as DataModelDescriptorComplex,
  DescriptorType,
  getLocalizedValue,
  ListProp,
} from '@simosol/iptim-data-model';
import { appModel, UserRole } from './App';
import { lang } from '../Lang';
import moment from 'moment';
import { computed, makeObservable, observable } from 'mobx';
import { Stand } from './Stands';
import DescriptorName from './DescriptorName';
import { UnitAge } from './Settings';
import { Descriptor } from '@simosol/iptim-data-model/lib/Descriptors';

type DescriptorAdditionalProps = {
  isVisibleOnMobile?: boolean | null;
  isEditableOnMobile?: boolean | null;
  isEditable?: boolean | null;
  isRequired?: boolean | null;
  maxLength?: number | null;
};

export const dataNodePropType =
  <T extends DescriptorType>(dataNodeProp: DataNodeProp, type: T): dataNodeProp is DataNodeProp<T> => {
    return dataNodeProp.descriptor[DESCRIPTOR_TYPE_PROP] === type;
  };

export type DescriptorComplex = DataModelDescriptorComplex & DescriptorAdditionalProps;
export class DataNode extends DataModelDataNode<DescriptorAdditionalProps> {}
export type DataNodePropComplex = DMDataNodePropComplex<DescriptorAdditionalProps>;
export type DataNodeProp<D extends DescriptorType = DescriptorType> = DMDataNodeProp<D, DescriptorAdditionalProps>;
export type DataNodePropNumber = DMDataNodePropNumber<DescriptorAdditionalProps>;
export type DataNodePropText = DMDataNodePropText<DescriptorAdditionalProps>;
export type DataNodePropCategory = DMDataNodePropCategory<DescriptorAdditionalProps>;
export type DataNodePropDynamicCategory = DMDataNodePropDynamicCategory<DescriptorAdditionalProps>;
export type DataNodePropDate = DMDataNodePropDate<DescriptorAdditionalProps>;

const getLocale = () => {
  return lang.locale;
};

/**
 * These keys are not shown in viewers
 */
const ignoredKeys = ['modifiedBy', 'uid', 'addedBy', 'modifiedAt', 'parentID', 'parcelNumber', 'parcelNumber', 'simulationErrors', DESCRIPTOR_TYPE_PROP];
export const propsDisplayFiltered = (props: DataNodeProp[]) => {
  return props.filter(prop =>
    prop.descriptor.isVisibleOnMobile &&
    ignoredKeys.indexOf(prop.key) === -1 &&
    !(dataNodePropType(prop, DescriptorType.complex)) &&
    (!!prop.displayValue || propEditable(prop)),
  );
};

export const propEditable = (prop: DataNodeProp) => {
  let editable = false;

  if (dataNodePropType(prop, DescriptorType.numeric)
    || dataNodePropType(prop, DescriptorType.text)
    || dataNodePropType(prop, DescriptorType.date)
    || dataNodePropType(prop, DescriptorType.category)
    || dataNodePropType(prop, DescriptorType.dynamicCategory)
  ) {
    editable = (
        appModel.userRole === UserRole.manager
        || (appModel.userRole === UserRole.assignee && (prop.parent as Stand).hasOperation && !appModel.isTapio)
        || (appModel.userRole === UserRole.assignee && appModel.isTapio)
      )
      && (!appModel.isTapio ? !!prop.descriptor.isEditable : !!prop.descriptor.isEditableOnMobile)
      && !appModel.isReadOnly;
  }

  return editable;
};

export const checkRequired = (dataNode: DataNode) => {
  const changedDataArr = dataNode.changedData.map(changeD => changeD.changedKeys.map(c => c.key))[0];
  const reqArr = dataNode.props.filter(p => p.descriptor.isRequired).map(c => c.key);
  return reqArr.every(req => changedDataArr?.includes(req));
};

const getDynamicCategoryOptions = (type: string) => {
  const options = [];
  if (type === 'User') {
    for (const user of appModel.users) {
      options.push({ value: user.uid, label: user.username });
    }
  }
  return options;
};

const listProps: ListProp[] = [
  { descriptorName: DescriptorName.stand1, primaryProp: { key: 'oid' } },
  { descriptorName: DescriptorName.plots, primaryProp: { key: 'oid' } },
  {
    descriptorName: DescriptorName.operations,
    primaryProp: { key: 'op_name' },
    secondaryProp: { key: 'date_planned', type: DescriptorType.date },
  },

  { descriptorName: DescriptorName.stand, primaryProp: { key: 'number', type: DescriptorType.numeric } },
  { descriptorName: DescriptorName.treatments, primaryProp: { key: 'treatmentType', type: DescriptorType.category } },
  {
    descriptorName: DescriptorName.treeStand,
    primaryProp: { key: 'type', type: DescriptorType.category },
    secondaryProp: { key: 'dataDate', type: DescriptorType.date },
  },
  {
    descriptorName: DescriptorName.operation,
    primaryProp: { key: 'type', type: DescriptorType.category },
    secondaryProp: { key: 'yearPlanned', type: DescriptorType.numeric },
  },
];

export const translateUnit = (value: string, params?: {}) => `${value}`.t(params);

export const dataNodeOptions: DataNodeOptions = {
  getDynamicCategoryOptions,
  listProps,
  translateUnit,
  getLocale: () => lang.locale,
  getId: (data: DDataNode) => data['id']?.toString() ?? data['uid']?.toString(),
  formatDate: (date: { year: number, month: number, date: number}) => moment(date).format('YYYY-MM-DD'),
  versionModelFactory: () => observable({ version: 0 }),
  arrayFactory: () =>  observable([], { deep: false }),
  ignoredChangedKeys: ['op_notes'],
};

export const createIptimDataNode =
  (data: DDataNode, descriptor: DescriptorComplex & DescriptorAdditionalProps): DataNode => {
    const options: DataNodeOptions = {
      getLocale,
      listProps,
      formatDate: (date: { year: number, month: number, date: number}) => moment(date).format('DD.MM.YYYY'),
      getId: (data: DDataNode) => data['id']?.toString() ?? '__UNDEFINED__',
      versionModelFactory: () => observable({ version: 0 }),
      arrayFactory: () => {
        return observable([], { deep: false });
      },
    };
    return new DataNode(data, descriptor, undefined, options);
  };

export const getLocalizedDisplayName = (descriptor: Descriptor) => {
  return getLocalizedValue(descriptor.localizedDisplayNames, getLocale(), descriptor.displayName);
};

export class DataNodePropWrapper<T extends DataNodeProp = DataNodeProp> {
  private readonly _node: T;
  constructor(node: T) {
    this._node = node;
  }

  get displayValue() {
    return this._node.displayValue;
  }

  get displayName() {
    return this._node.displayName;
  }

  get node() { return this._node; }
  get valueString() { return this.node.value?.toString() ?? ''; }

  setValueFromString = (newValue: string) => {
    // noinspection JSConstantReassignment
    this.node.value = newValue === ''
      ? undefined
      : newValue
    ;
  }
}

export class DataNodePropNumberWrapper extends DataNodePropWrapper<DataNodePropNumber> {
  constructor(node: DataNodePropNumber) {
    super(node);
    makeObservable(this);
  }

  @computed
  private get unitAge(): UnitAge {
    return appModel.settings.unitAge;
  }

  private get divider(): number {
    const descriptor = this.node.descriptor;
    if (descriptor.kind === 'proportion') {
      return 0.01;
    } else if (descriptor.kind === 'age' && this.unitAge === 'year') {
      return 12;
    }
    return 1;
  }

  /**
   * Corrected value. If value kind is proportion then it's percentage *100.
   * If value kind is age, it is converted from months to years
   * @private
   */
  private get kindValue(): number | undefined {
    let value = this.node.value;
    if (value === undefined || value === null) return undefined;

    const descriptor = this.node.descriptor;
    if (descriptor.precision !== undefined) {
      const pow = Math.pow(10, descriptor.precision);
      value = (Math.round(value * pow) / pow);
    }
    value /= this.divider;
    if (descriptor.kind === 'age' && this.unitAge === 'year') {
      value = Math.round(value * 10) / 10;
    }
    return value;
  }

  get displayValue() {
    // displayValue is valueString + translated unit
    const value = this.kindValue;
    if (value === undefined) return '';
    let resValue: string = value.toString();
    const unit = this.unit;
    if (unit) resValue += (unit === '%' ? '' : ' ') + dataNodeOptions.translateUnit?.(unit, { count: value });
    return resValue;
  }

  get minimum() {
    const min = this.node.minimum;
    return min ? min / this.divider : undefined;
  }

  get maximum() {
    const max = this.node.maximum;
    return max ? max / this.divider : undefined;
  }

  get valueString(): string {
    return this.kindValue?.toString() ?? '';
  }

  setValueFromString = (newValue: string) => {
    if (newValue === '') {
      this.node.value = undefined;
    } else {
      let newNumValue = Number(newValue) * this.divider;
      if (isNaN(newNumValue)) {
        this.node.value = undefined;
      } else {
        if (this.node.descriptor.kind === 'age') {
          newNumValue = Math.round(newNumValue);
        }
        this.node.value = newNumValue;
      }
    }
  }

  get unit() {
    if (this.node.descriptor.kind === 'age') return this.unitAge;
    return this.node.descriptor.unit;
  }
}
