import * as React from 'react';
import { appModel } from '../../models/App';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import UiText from '../../uicomponents/UiText';
import DataPreloader from '../../uicomponents/DataPreloader';
import { PageTask } from '../../Browser';
import { LangKey } from '../../LangKey';
import AppToolbar from '../app/appBar/AppToolbar';
import { Task, TaskStatus } from '../../models/Tasks';
import { createStyles, makeStyles } from '@mui/styles';
import SortButton, { SortButtonModel } from './SortButton';
import { observable } from 'mobx';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { fixStatus } from '../task/TaskInfo';
import { Checkbox, FormControlLabel } from '@mui/material';

const useStyles = makeStyles(
  createStyles({
    spacer: {
      flexGrow: 1,
    },
  }),
);

export const taskColorFromStatus = (status?: TaskStatus) => {
  let color = '#6c6c6c';
  if (!status) return color;
  switch (status) {
    case fixStatus(TaskStatus.assigned):
      color = '#df6664';
      break;
    case fixStatus(TaskStatus.ongoing):
      color = '#fed966';
      break;
    case fixStatus(TaskStatus.executed):
      color = '#92c27d';
      break;
  }
  return color;
};

const sortModel: SortButtonModel<'taskId' | 'status' | 'dateAsc' | 'dateDesc'> = observable({
  items: [
    { label: LangKey.TasksSortId, value: 'taskId' },
    { label: LangKey.TasksSortStatus, value: 'status' },
    { label: LangKey.TasksSortDateAsc, value: 'dateAsc' },
    { label: LangKey.TasksSortDateDesc, value: 'dateDesc' },
  ],
  value: 'taskId',
});

const sortTaskId = (task1: Task, task2: Task) => {
  const id1 = task1.stand.listPrimaryPropDisplayValue || 0;
  const id2 = task2.stand.listSecondaryPropDisplayValue || 0;
  return id1 < id2 ? 1 : -1;
};

const sortTaskDate = (asc: boolean) => (task1: Task, task2: Task) => {
  const date1 = appModel.isTapio
    ? task1.propDateStart ? moment(task1.propDateStart.value) : undefined
    : task1.propDateEnd ? moment(task1.propDateEnd.value) : undefined;
  const date2 = appModel.isTapio
    ? task2.propDateStart ? moment(task2.propDateStart.value) : undefined
    : task2.propDateEnd ? moment(task2.propDateEnd.value) : undefined;
  const m = asc ? 1 : -1;
  if (date1 && date2) return (date1.isBefore(date2) ? -1 : 1) * m;
  else if (date1) return -1;
  else if (date2) return 1;
  return 0;
};

const sortTaskStatus = (task1: Task, task2: Task) => {
  const status1 = task1.status;
  const status2 = task2.status;
  return status1 < status2 ? -1 : 1;
};

const Tasks = observer(() => {
  const _onTaskClick = (task: Task) => () => {
    appModel.browser.page = { p: 'task', p1: task.id, p2: PageTask.info };
  };
  const classes = useStyles();
  const tasks = appModel.tasks.tasks.concat();
  if (sortModel.value === 'taskId') {
    tasks.sort(sortTaskId);
  } else if (sortModel.value === 'dateAsc') {
    tasks.sort(sortTaskDate(true));
  } else if (sortModel.value === 'dateDesc') {
    tasks.sort(sortTaskDate(false));
  } else if (sortModel.value === 'status') {
    tasks.sort(sortTaskStatus);
  }

  const showOnlyAssignedToMe = appModel.onlyAssigned;
  return (
    <>
      <AppToolbar title={LangKey.TasksTitle} button={{ type: 'menu' }}>
        <>
          <div className={classes.spacer} />
          {appModel.isTapio && (
            <FormControlLabel
              control={ (
                <Checkbox
                  checked={showOnlyAssignedToMe}
                  onChange={() => appModel.onlyAssigned = !appModel.onlyAssigned}
                  color="primary"
                />
              )}
              label={LangKey.TaskAssigned.t()}
            />
          )}
          <SortButton model={sortModel} />
        </>
      </AppToolbar>
      <DataPreloader>
        {tasks.map(task => (
          <ListItem button key={task.id} onClick={_onTaskClick(task)}>
            <ListItemIcon>
              <svg height="24" width="24">
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  stroke="none"
                  fill={taskColorFromStatus(task.status)}
                />
              </svg>
            </ListItemIcon>
            <ListItemText
              primary={task.nameArea}
              secondary={task.joinSecondary([task.stand.municipality, task.project.name, `${LangKey.TaskStand.t()} ${task.stand.name}`, task.assignee?.displayValue])}
            />
            <UiText>{!appModel.isTapio ? task.dateEnd : task.yearPlanned}</UiText>
          </ListItem>
        ))}
      </DataPreloader>
    </>
  );
});

export default Tasks;
