import * as React from 'react';
import UiText from '../../uicomponents/UiText';
import { appModel } from '../../models/App';
import { observer } from 'mobx-react';
import DataPreloader from '../../uicomponents/DataPreloader';
import { Task } from '../../models/Tasks';
import { PageTask } from '../../Browser';
import { Note } from '../../models/Notes';
import Fab from '../../uicomponents/Fab';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Add from '@mui/icons-material/Add';

interface TaskNotesProps {}

@observer
class TaskNotes extends React.Component<TaskNotesProps> {
  constructor(props: TaskNotesProps) {
    super(props);
  }

  private _onNoteClick = (note: Note, task: Task) => () => {
    appModel.browser.page = { p: 'task', p1: task.id, p2: PageTask.notes, p3: 'edit', p4: note.id };
  }

  render() {
    const task = appModel.tasks.currentTask;
    return (
      <DataPreloader>
        {task &&
        <>
          {task.notes.map(note => (
            <ListItem button key={note.id} onClick={this._onNoteClick(note, task)}>
              <ListItemText
                primary={note.text}
              />
              <UiText>{note.addedAt}</UiText>
            </ListItem>
          ))}
          <Fab
            page={{ p: 'task', p1: task.id, p2: PageTask.notes, p3: 'new' }}
            style={{ position: 'absolute', right: 16, bottom: 16 }}
            color={'primary'}
          >
            <Add />
          </Fab>
        </>
        }
      </DataPreloader>
    );
  }
}

export default TaskNotes;
