import * as React from 'react';
import { runInAction } from 'mobx';

import { appModel } from '../../models/App';
import Project from '../../models/Project';
import { LangKey } from '../../LangKey';

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import Map from '@mui/icons-material/Map';
import MoreVert from '@mui/icons-material/MoreVert';
import ReadMoreOutlined from '@mui/icons-material/ReadMoreOutlined';
import MapOutlined from '@mui/icons-material/MapOutlined';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  listItem: {
    minWidth: theme.spacing(4),
  },
}));

type Props = {
  model: Project;
};

const ProjectMenu = (props: Props) => {
  const { model } = props;
  const styles = useStyles();
  const projects = appModel.projects;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const handleOpenProject = () => {
    projects.showProject(model);
  };
  const handleOpenEditor = () => {
    projects.openEditor(model);
  };
  const handleOpenMap = () => {
    projects.openMap(model);
  };
  const handleDownload = () => {
    runInAction(() => {
      projects.clearSelectAll();
      projects.toggleProject(model);
    });
    projects.layersLoader.downloadDialogOpened = true;
    setAnchorEl(null);
  };

  return (
    <div className={styles.root}>
      <IconButton onClick={handleOpenMenu} size="large">
        <MoreVert color="action" />
      </IconButton>
      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={handleClose}
        >
          <MenuItem onClick={handleOpenProject}>
            <ListItemIcon className={styles.listItem}>
              <ReadMoreOutlined fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary={LangKey.ProjectsOpenProject.t()}/>
          </MenuItem>
          <MenuItem onClick={handleOpenMap}>
            <ListItemIcon className={styles.listItem}>
              <MapOutlined fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary={LangKey.ProjectsOpenMap.t()}/>
          </MenuItem>
          {appModel.isTapioIsEstates && (
            <MenuItem onClick={handleOpenEditor}>
              <ListItemIcon className={styles.listItem}>
                <Map fontSize="small"/>
              </ListItemIcon>
              <ListItemText primary={LangKey.ProjectsOpenEditor.t()}/>
            </MenuItem>
          )}
          <MenuItem
            onClick={handleDownload}
          >
            <ListItemIcon>
              <DownloadOutlined fontSize={'small'} />
            </ListItemIcon>
            <ListItemText primary={LangKey.ProjectsDownloadMap.t()}/>
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default ProjectMenu;
