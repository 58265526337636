import * as React from 'react';
import { observer } from 'mobx-react-lite';

import { LangKey } from '../../LangKey';
import { appModel } from '../../models/App';
import { translateUnit } from '../../models/DataNodeUtils';
import { UnitAge } from '../../models/Settings';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const useStyles = makeStyles((theme: Theme) => createStyles({
  group: {
    margin: theme.spacing(3, 0),
  },
}));

const AgeKindSelector = observer(() => {
  const classes = useStyles();
  const settings = appModel.settings;
  const onRadioChange = (event: React.ChangeEvent<{}>, value: string) => {
    settings.unitAgeChange(value as UnitAge);
  };
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{LangKey.SettingsUnitAge.t()}</FormLabel>
      <RadioGroup
        className={classes.group}
        value={settings.unitAge}
        onChange={onRadioChange}
      >
        { settings.allUnitAges.map(unitAge => (
          <FormControlLabel
            key={unitAge.key}
            value={unitAge.key}
            control={<Radio color={'default'} />}
            label={translateUnit(unitAge.value)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
});

export default AgeKindSelector;
