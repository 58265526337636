import { ApiMethodResponse } from '../API';
import { appModel, DApp, DPartialApp } from './App';
import { makeObservable, observable } from 'mobx';
import { MapboxDB } from './MapboxDB';
import axios from 'axios';

export default class DataLoader {
  static readonly LAYER_ALL_ESTATES = 1;
  static readonly LAYER_VECTOR_BASE = 2;
  static readonly LAYER_STAND_BORDERS  = 3;

  @observable
  partsLoaded = 0;

  @observable
  partsTotal?: number;

  constructor() {
    makeObservable(this);
  }

  load = async (): Promise<ApiMethodResponse<DApp>> => {
    const fg = appModel.isTapio ? await appModel.api.dataGetFreeGeometry() : undefined;
    const structure = appModel.isTapio ? await appModel.api.dataGetStructure() : undefined;

    const res = await appModel.api.getData();
    if (!res.error && res.data) {
      this.partsLoaded = 1;
      this.partsTotal = res.data.numParts;
      const appData: DApp = {
        projects: [],
        role: res.data.role,
        isReadOnly: res.data.isReadOnly,
        users: [],
      };
      this._mergeAppData(
        appData,
        {
          ...res.data,
          projects: res.data.projects.map((p) => {
            return { ...p, structure: structure?.data?.data };
          }),
        });
      if (fg && !fg.error) {
        //  @ts-ignore
        appData.freeGeometries = fg;
      }
      let resKey = res.data.resKey;
      let hasMore = res.data.hasMore;
      while (hasMore) {
        const res = await appModel.api.getData(resKey);
        this.partsLoaded += 1;
        if (!res.error && res.data) {
          this._mergeAppData(
            appData,
            {
              ...res.data,
              projects: res.data.projects.map((p) => {
                return { ...p, structure: structure?.data?.data };
              }),
            },
        );
          hasMore = res.data.hasMore;
          if (res.data.resKey) {
            resKey = res.data.resKey;
          }
        } else {
          return { error: res.error };
        }
      }

      this._preloadMap();
      return { data: appData };
    } else {
      appModel.isOffline = true;
      return { error: res.error };
    }
  }

  private _preloadMap = async () => {
    [
      `${window.location.origin}/temp/loisto_styles_v3.json`,
      'https://maps.loisto.xyz/styles/loisto-mariner/sprites/v1/topo@2x.json',
      'https://maps.loisto.xyz/styles/loisto-mariner/sprites/v1/topo@2x.png',
      'https://forestkit.etapio.fi/static/mtk/osdstyles.json',
    ].map(this._preloadMapUrl);
  }

  private _preloadMapUrl = async (url: string) => {
    const isPreloadedStyles = !!(await MapboxDB.indexedGet(url));
    if (!isPreloadedStyles) {
      const jsonData = await axios.get(url);
      if (jsonData.data) {
        MapboxDB.indexedPut(url, jsonData.data);
      }
    }
  }

  private _mergeAppData = (target: DApp, newData: DPartialApp) => {
    if (newData.users) target.users = newData.users;
    for (const newProject of newData.projects) {
      let exists = false;
      for (const project of target.projects) {
        if (newProject.uid === project.uid) {
          exists = true;
          project.data = project.data.concat(newProject.data);
        }
      }
      if (!exists && newProject.structure) {
        target.projects.push({
          uid: newProject.uid,
          structure: newProject.structure,
          data: newProject.data,
          name: newProject.name,
          code: newProject.code,
          clients: newProject.clients,
          geom: newProject.geom,
        });
      }
    }
  }
}
