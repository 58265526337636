import * as React from 'react';

import { appModel, UserRole } from '../../models/App';
import Confirm from '../../uicomponents/Confirm';
import { Page } from '../../Browser';
import { LangKey } from '../../LangKey';
import ListItemText from '../../uicomponents/ListItemText';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import { SvgIconProps } from '@mui/material/SvgIcon';
import List from '@mui/icons-material/List';
import Assignment from '@mui/icons-material/Assignment';
import Settings from '@mui/icons-material/Settings';
import FilterTiltShift from '@mui/icons-material/FilterTiltShift';
import GpsFixed from '@mui/icons-material/GpsFixed';
import { observer } from 'mobx-react-lite';
import OfflineToggle from './OfflineToggle';
import UiText from '../../uicomponents/UiText';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Spacer from '../../uicomponents/Spacer';

const useStyles = makeStyles(() => createStyles({
  text: {
    fontWeight: 700,
    cursor: 'pointer',
  },
}));

type MainMenuButtonProps = {
  selected: boolean;
  clickPage: Page;
  label: string;
  icon: React.ReactElement<SvgIconProps>;
};

class MainMenuButton extends React.Component<MainMenuButtonProps> {
  private _onClick = () => {
    appModel.drawerOpen = false;
    appModel.browser.page = this.props.clickPage;
  }
  render() {
    const { selected, label, icon } = this.props;
    return (
      <ListItem
        button
        selected={selected}
        onClick={this._onClick}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{label}</ListItemText>
      </ListItem>
    );
  }
}

interface MainMenuProps {
  page: Page;
}

const MainMenu = observer((props: MainMenuProps) => {
  const { page } = props;
  const styles = useStyles();

  const p = page.p;
  const userRole = appModel.userRole;

  return (
    <>
      {appModel.isTapioIsEstates && <OfflineToggle />}
      {(userRole === UserRole.manager || userRole === UserRole.auditor) &&
        <MainMenuButton
          selected={p === 'projects' || p === '' || p === 'project' || p === 'stand'}
          clickPage={{ p: 'projects' }}
          label={LangKey.ProjectsTitle}
          icon={<List /> as React.ReactElement<SvgIconProps>}
        />
      }
      {!appModel.isTapioIsEstates && (
        <MainMenuButton
          selected={p === 'tasks' || (p === '' && userRole === UserRole.assignee)}
          clickPage={{ p: 'tasks' }}
          label={LangKey.TasksTitle}
          icon={<Assignment/> as React.ReactElement<SvgIconProps>}
        />
      )}
      {appModel.isTapioIsEstates && (
        <MainMenuButton
          selected={p === 'map' && page.p1 === 'gps'}
          clickPage={{ p: 'map', p1: 'gps', p2: 'recording' }}
          label={LangKey.MapElementsTitle}
          icon={<GpsFixed /> as React.ReactElement<SvgIconProps>}
        />
      )}
      {appModel.isTapio && !appModel.isOffline && (
        <MainMenuButton
          selected={p === 'freeGeometries' || p === 'freeGeometry'}
          clickPage={{ p: 'freeGeometries' }}
          label={LangKey.FreeGeometryTitle}
          icon={<FilterTiltShift /> as React.ReactElement<SvgIconProps>}
        />
      )}
      <MainMenuButton
        selected={p === 'settings'}
        clickPage={{ p: 'settings' }}
        label={LangKey.SettingsTitle}
        icon={<Settings /> as React.ReactElement<SvgIconProps>}
      />
      <Spacer />
      <Confirm
        message={LangKey.AuthLogoutConfirm.t()}
        variant={'listItem'}
        onClick={appModel.logout}
        icon={<PowerSettingsNew/>}
      >
        <UiText className={styles.text} variant={'body1'}>{LangKey.AuthLogoutButton}</UiText>
      </Confirm>
    </>
  );
});

export default MainMenu;
