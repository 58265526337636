import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MapButton, OwnLocationButton } from '@simosol/stands-map/';
import Paper from '@mui/material/Paper';
import { NearMe } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: 'white',
    borderRadius: 24,
  },
  tooltip: {
    position: 'relative',
    right: theme.spacing(6.5),
    bottom: theme.spacing(2.5),
  },
  geo: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    borderRadius: '50%',
    overflow: 'visible',
    opacity: 1,
    visibility: 'visible',
    transition: '.1s all ease-in-out',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      right: 0,
      bottom: 0,
    },
  },
  nearMeBtn: {
    padding: 12,
  },
}));

type Props = {
  openGPSMenu?: (event: React.MouseEvent) => void;
} & Parameters<typeof OwnLocationButton>[0];

const OwnLocation: React.FunctionComponent<Props> = ({ openGPSMenu, ...props }) => {
  const styles = useStyles();

  const useGPSFunc = !!openGPSMenu && props.positionLock;
  return useGPSFunc ? (
    <MapButton className={props.className}>
      <Paper className={styles.root} elevation={3}>
        <IconButton
          onClick={openGPSMenu}
          className={styles.nearMeBtn}
        >
          <NearMe />
        </IconButton>
      </Paper>

    </MapButton>
  ) : (
    <Paper className={styles.root} elevation={3}>
      <OwnLocationButton
       {...props}
      />
    </Paper>
  );
};

export default OwnLocation;
