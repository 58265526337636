import * as React from 'react';
import Hidden from '@mui/material/Hidden';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const HiddenDesktop = (props: { children: React.ReactNode }) => {
  return (
    <Hidden mdUp>{props.children}</Hidden>
  );
};

export const HiddenMobile = (props: { children: React.ReactNode }) => {
  return <Hidden mdDown>{props.children}</Hidden>;
};

export const useMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
};

export const useSmallMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};
