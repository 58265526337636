type InstancePare = {
  id: string,
  uid: string,
};

export const NEW_ELEMENTS_KEY = 'eTapio_eOffKIT_newElements';

class InstanceControl {
  private instancePare: InstancePare[] = [];

  constructor() {
    const getFromLocal = localStorage.getItem(NEW_ELEMENTS_KEY);
    this.instancePare = getFromLocal ? JSON.parse(getFromLocal) : [];
  }

  setNewInstance = (uid: string | number) => {
    this.instancePare.push({ uid: `${uid}`, id: '' });
    localStorage.setItem(NEW_ELEMENTS_KEY, JSON.stringify(this.instancePare));
  }

  setId = (uid: string, id: string) => {
    let was = false;
    this.instancePare = this.instancePare.map((pos) => {
      if (pos.uid === uid) {
        was = true;
        return { uid, id };
      }
      return pos;
    });
    if (!was) {
      this.instancePare.push({ uid, id });
    }
    localStorage.setItem(NEW_ELEMENTS_KEY, JSON.stringify(this.instancePare));
  }

  getId = (uid: string) => {
    return this.instancePare.find(pos => pos.uid === uid)?.id;
  }

  fixPosition = (positions: {
    uid: string;
    parentKey?: string;
  }[]) => {
    return positions.map((pos) => {
      const id = this.getId(pos.uid);
      if (id) {
        return { ...pos, uid: id };
      }
      return pos;
    });
  }
}

export default InstanceControl;
